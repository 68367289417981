/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Pagination, Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, Tooltip } from '@nextui-org/react';
import { IconEdit, IconEye, IconPlus, IconSearch, IconTrash } from '@tabler/icons-react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { Constants } from '../../utils';
import { OrdersService } from '../../services';
import ModalCreate from './createOrderTemplate';
import ModalView from './viewOrderTemplate';

const MODAL_ACTION = {
  NONE: 0,
  VIEW: 1,
  EDIT: 2,
  CREATE: 3,
  DELETE: 4,
}

const OrderTemplates = () => {

  const [selectedOrder, setSelectedOrder] = useState();
  const [modalAction, setModalAction] = useState(MODAL_ACTION.NONE);
  const { canResetFilter, orders, filterBy, goToPage, isLoading, pagination, reload , deleteOrder } = useFetchTable();

  const onSelectItem = (order, action) => {
    setSelectedOrder(order);
    setModalAction(action);
  }

  const closeModal = (reloading = false) => {
    setSelectedOrder(null);
    setModalAction(MODAL_ACTION.NONE);
    if (reloading) reload();
  }

  return (
    <>
      {isLoading && (
        <div className="w-screen h-screen fixed inset-0 z-[70] flex justify-center items-center bg-white/30">
          <Spinner />
        </div>
      )}

      <Modal
        size="4xl"
        isOpen={modalAction === MODAL_ACTION.VIEW}
        onClose={() => closeModal()}
        backdrop="blur"
        scrollBehavior="outside"
      >
        <ModalContent>
          {(onClose) => (
            <ModalView order={selectedOrder} onClose={onClose} />
          )}
        </ModalContent>
      </Modal>

      <Modal
        size="4xl"
        isOpen={modalAction === MODAL_ACTION.EDIT}
        onClose={() => closeModal()}
        backdrop="blur"
        scrollBehavior="outside"
      >
        <ModalContent>
          {(onClose) => (
            <ModalCreate order={selectedOrder}onClose={onClose} onSuccess={() => closeModal(true)} />
          )}
        </ModalContent>
      </Modal>

      <Modal
        size="4xl"
        isOpen={modalAction === MODAL_ACTION.CREATE}
        onClose={() => closeModal()}
        backdrop="blur"
        scrollBehavior="outside"
      >
        <ModalContent>
          {(onClose) => (
            <ModalCreate  onClose={onClose} onSuccess={() => closeModal(true)} />
          )}
        </ModalContent>
      </Modal>

      <Modal
        size="sm"
        isOpen={modalAction === MODAL_ACTION.DELETE}
        onClose={() => closeModal()}
        backdrop="blur"
        scrollBehavior="outside"
      >
        <ModalContent>
          {(onClose) => (
            <ModalDelete
              onClose={onClose}
              onDelete={()=> {
                deleteOrder(selectedOrder);
                closeModal(true);
              }}
            />
          )}
        </ModalContent>
      </Modal>

      <Filters
        canResetFilter={canResetFilter}
        filterBy={filterBy}
        resetFilter={() => reload()}
      />

      <Table aria-label="Plantillas"
        topContent={
          <div className="flex flex-row justify-between items-center gap-4">
            <h3 className="text-xl font-medium text-primaryDark">Plantillas</h3>
            <Button
              color="primary"
              className="pl-2"
              startContent={<IconPlus color="white" />}
              onClick={() => onSelectItem(null, MODAL_ACTION.CREATE)}
            >
              Nueva
            </Button>
          </div>
        }
        topContentPlacement="inside"
      >
        <TableHeader>
          <TableColumn>ID</TableColumn>
          <TableColumn>Trabajador</TableColumn>
          <TableColumn>Fecha de creación</TableColumn>
          <TableColumn>Estatus</TableColumn>
          <TableColumn align="end" />
        </TableHeader>
        <TableBody items={orders}>
          {(order) => {

            return (
              <TableRow key={order.id}>
                <TableCell>
                  { order?.id }
                </TableCell>
                <TableCell>
                  { order?.cleaner?.person.fullName }
                </TableCell>
                <TableCell>
                  { moment(order?.created_at).format('DD/MM/YYYY') }
                </TableCell>
                <TableCell>
                  { order?.status_text }
                </TableCell>
                <TableCell align="right">
                  <div className="relative flex justify-end items-center gap-2">
                    <Tooltip content="Ver detalles">
                      <IconEye onClick={() => onSelectItem(order, MODAL_ACTION.VIEW)} />
                    </Tooltip>
                    <Tooltip content="Editar">
                      <IconEdit onClick={() => onSelectItem(order, MODAL_ACTION.EDIT)} />
                    </Tooltip>
                    <Tooltip content="Eliminar">
                      <IconTrash onClick={() => onSelectItem(order, MODAL_ACTION.DELETE)} />
                    </Tooltip>
                  </div>
                </TableCell>
              </TableRow>
            )
          }}
        </TableBody>
      </Table>

      <div className="flex w-full justify-center mt-4">
        <Pagination
          showControls
          variant="bordered"
          page={pagination.page}
          total={pagination.pages}
          onChange={goToPage}
        />
      </div>
    </>
  );
}

const Filters = ({ canResetFilter, filterBy, resetFilter }) => {

  const initialFilter = {
    search: '',
    since: '',
    until: '',
    status: '',
  };
  const [form, setForm] = useState(initialFilter);

  const onChange = (value, target) => {
    setForm(s => ({ ...s, [target]: value }));
    filterBy(value, target);
  }

  return (
    <section className="mb-4 flex flex-col lg:flex-row items-end gap-4">
      <Input
        classNames={{
          base: 'w-full sm:max-w-[16rem]',
          inputWrapper: 'border-1 h-10 bg-white',
        }}
        label="Buscar"
        labelPlacement="outside"
        placeholder="Nombre/Apellido de trabajador"
        startContent={<IconSearch />}
        variant="bordered"
        value={form.search}
        onValueChange={v => onChange(v, 'search')}
      />
      <Input
        type="date"
        classNames={{
          base: 'w-full sm:max-w-[9rem]',
          inputWrapper: 'border-1 h-10 bg-white',
          input: `pr-0 text-${!!form.since ? '[]':'foreground-400'}`,
        }}
        label="Fecha desde"
        labelPlacement="outside"
        placeholder=" "
        variant="bordered"
        value={form.since}
        onValueChange={v => onChange(v, 'since')}
        //min={new Date().toISOString().split('T')[0]}
      />
      <Input
        type="date"
        classNames={{
          base: 'w-full sm:max-w-[9rem]',
          inputWrapper: 'border-1 h-10 bg-white',
          input: `pr-0 text-${!!form.until ? '[]':'foreground-400'}`,
        }}
        label="Fecha hasta"
        labelPlacement="outside"
        placeholder=" "
        variant="bordered"
        value={form.until}
        onValueChange={v => onChange(v, 'until')}
        //min={new Date().toISOString().split('T')[0]}
      />
      {canResetFilter && (
        <Button
          variant="light"
          className="text-primaryDark"
          onClick={() => {
            setForm(initialFilter);
            resetFilter();
          }}
        >
          Limpiar filtros
        </Button>
      )}
    </section>
  )
}

const ModalDelete = ({ onClose, onDelete }) => {
  return (
    <>
      <ModalHeader className="flex flex-col gap-1">Confirmación</ModalHeader>
      <ModalBody>
        <p>¿Estás seguro de eliminar esta plantilla de horarios?</p>
      </ModalBody>
      <ModalFooter className="justify-evenly">
        <Button variant="light" onPress={onClose}>Cancelar</Button>
        <Button color="primary" onPress={onDelete}>Aceptar</Button>
      </ModalFooter>
    </>
  )
}

const useFetchTable = () => {
  const initialFilters = {
    page: 1,
    perPage: Constants.PER_PAGE,
    search: '',
    since: '',
    until: '',
    status: '',
  };

  const initialPagination = {
    page: 1,
    pages: 1,
    total: 0,
    perPage: Constants.PER_PAGE,
    isFirstPage: true,
    isLastPage: true,
  };

  const [data, setData] = useState([]);
  const [canFetch, setCanFetch] = useState(true);
  const [filters, setFilters] = useState({ ...initialFilters });
  const [pagination, setPagination] = useState({ ...initialPagination });

  const debounceTime = 500;
  const debounce = useRef();

  const fetchData = async () => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      const response = await OrdersService.getOrderTemplates(filters);
      console.log(response)
      const { data, ...rest } = response;

      setData(data);
      setPagination(rest);
      setCanFetch(true);

    } catch (error) {
      setData([]);
      onError(String(error));
      setPagination(initialPagination);
      setCanFetch(true);
    }
  }

  const deleteItem = async (item) => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      const data = {
        order_template_id: item.id,
        cleaner_id: item.cleaner_id,
      };

      await OrdersService.deleteOrderTemplate(data);
      toast.success('Orden eliminada con éxito');
      setCanFetch(true);
      fetchData();
    } catch (error) {
      setCanFetch(true);
      onError(String(error));
    }
  }

  const updateItem = async (order) => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      await OrdersService.editOrderTemplate(order);
      toast.success('Orden actualizada con éxito');
      setCanFetch(true);
      fetchData();

    } catch (error) {
      setCanFetch(true);
      onError(String(error));
    }
  }

  const getCurrentFilter = () => {
    // Truco para obtener el estado actualizado (filters es mantenida con el estado actual por el componente Pagination)
    let f;
    setFilters(s => {
      f = s;
      return s;
    });
    return f;
  }

  const getCurrentPagination = () => {
    // Truco para obtener el estado actualizado (pagination es mantenida con el estado actual por el componente Pagination)
    let pag;
    setPagination(s => {
      pag = s;
      return s;
    });
    return pag;
  }

  const canResetFilter = () => {
    const { page, perPage, ...initial } = initialFilters;
    const { page: _, perPage: __, ...current } = filters;
    const initFilter = JSON.stringify(initial);
    const currFilter = JSON.stringify(current);
    return initFilter !== currFilter;
  }

  const onError = (msg) => toast.error(msg);

  const reload = (inSamePage = false) => {
    setCanFetch(true);
    if (!inSamePage) setFilters(initialFilters);
    else fetchData();
  }

  const goToPage = (page) => {
    const filter = getCurrentFilter();
    const pagination = getCurrentPagination();
    if (page >= 1 && page <= pagination.pages && page !== pagination.page) {
      setCanFetch(true);
      setFilters({ ...filter, page });
    }
  }

  const changePerPage = (perPage) => {
    const filter = getCurrentFilter();
    setCanFetch(true);
    setFilters({ ...filter, perPage });
  }

  const filterBy = (value, target) => {
    if (debounce.current) clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      setCanFetch(true);
      const filter = getCurrentFilter();
      setFilters({ ...filter, page: 1, [target]: value });
    }, debounceTime);
  }

  useEffect(() => {
    fetchData();
  }, [filters]);

  return {
    canResetFilter: canResetFilter(),
    changePerPage,
    deleteOrder: deleteItem,
    filterBy,
    filters,
    goToPage,
    isLoading: !canFetch,
    orders: data,
    pagination,
    reload,
    updateOrder: updateItem,
  }
}

export default OrderTemplates;
