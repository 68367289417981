/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { ModalHeader, ModalBody, ModalFooter, Button } from '@nextui-org/react';
import moment from 'moment';

const ModalViewOrderTemplate = ({ order, onSuccess, onClose }) => {
    const [form, setForm] = useState({
        cleaner_id: '',
        cleaner_name: '',
        details: [],
        _searchStore: '',
        _searchCleaner: '',
    });

    useEffect(() => {
        setForm({
            ...form,
            order_template_id: order.id,
            cleaner_id: order.cleaner_id,
            cleaner_name: order.cleaner?.person?.fullName,
            details: order.orderTemplateDetails?.map(row => ({
                store_id: row.store_id,
                store_name: row.store?.name,
                observations: row.observations,
                schedules: row?.orderTemplateDetailSchedules?.map(schedule => ({
                    day_of_week: schedule.day_of_week,
                    start_time: schedule.start_time,
                    end_time: schedule.end_time,
                })),
            })),
        });
    }, []);

    return (
        <>
            <ModalHeader className="flex flex-col gap-1">
                Plantilla de Orden
            </ModalHeader>
            <ModalBody>
                {/* Selección de cleaner */}
                <p className="font-semibold">Limpiador: { form.cleaner_name }</p>

                {/* Horarios */}
                <p className="text-lg font-semibold text-primary mb-0">Programar limpieza</p>

                <div>
                    <div className="grid grid-cols-9 gap-2 bg-primary text-white text-sm -mx-6 p-2">
                        <span className="col-span-2">Tienda</span>
                        <span>Lunes</span>
                        <span>Martes</span>
                        <span>Miércoles</span>
                        <span>Jueves</span>
                        <span>Viernes</span>
                        <span>Sábado</span>
                        <span>Domingo</span>
                    </div>

                    {/* Mostrar tiendas añadidas */}
                    {form.details.map((detail, index) => {
                        return (
                            <div
                                key={index}
                                className="grid grid-cols-9 gap-2 text-sm -mx-6 p-2"
                            >
                                <div>
                                    <p className="font-semibold">{ detail.store_name }</p>
                                    <p>{detail.observations}</p>
                                </div>
                                {detail.schedules.map((schedule, idx) => {
                                    // Obtener el nombre del día basado en el value de day_of_week
                                    // const dayName = daysOfWeek.find(d => d.value === schedule.day_of_week)?.name || 'Día desconocido';

                                    // Busca la ubicación en la celda en base al día de la semana asignado
                                    const gridColumnStart = schedule.day_of_week + 2;
                                    const gridColumnEnd = gridColumnStart + 1;

                                    return (
                                        <div
                                            key={idx}
                                            style={{ gridColumnStart, gridColumnEnd }}
                                        >
                                            <p>{ moment(schedule.start_time, 'HH:mm').format('h:mm a') }</p>
                                            <p>{ moment(schedule.end_time, 'HH:mm').format('h:mm a') }</p>
                                        </div>
                                    );
                                })}
                            </div>
                        )
                    })}
                </div>
            </ModalBody>
            <ModalFooter>
                <Button style={{ width: "auto", maxWidth: "150px" }} onPress={onClose}>Cerrar</Button>
            </ModalFooter>
        </>
    );
};

export default ModalViewOrderTemplate;
