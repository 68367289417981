/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Input, ModalHeader, ModalBody, ModalFooter, Button, Avatar, Divider, Select, SelectItem } from '@nextui-org/react';
import toast from 'react-hot-toast';
import { CleanersService } from '../../services';
import { Constants } from '../../utils';

const levelSelect = [
  { label: 'Supervisor', value: Constants.LEVELS.SUPERVISOR }
];

const ModalCreate = ({ cleaner, onSuccess, onClose }) => {
  const { onSubmit } = useFetch();
  const [form, setForm] = useState({
    email: '',
    name: '',
    start_date: '',
    lastname: '',
    currentPhoto: '',
    document: '',
    phone: '',
    photo: '',
    photoPreview: '',
    documentPhoto: '',
    documentPhotoPreview: '',
    country: '',
    address: '',
    bankName: '',
    bankNumber: '',
    bankRoute: '',
    hasFile: true,
    level_id: Constants.LEVELS.SUPERVISOR.toString(),
    password: ''
  });

  const onChange = (value, target) => {
    let photoPreview = form.photoPreview;
    let documentPhotoPreview = form.documentPhotoPreview;
    if (target === 'photo') {
      photoPreview = URL.createObjectURL(value);
    }
    if (target === 'documentPhoto') {
      documentPhotoPreview = URL.createObjectURL(value);
    }
    setForm(s => ({
      ...s,
      [target]: value,
      photoPreview,
      documentPhotoPreview,
    }));
  }

  const submit = async () => {
    const isSuccess = await onSubmit(form);
    if (isSuccess) onSuccess();
  }

  return (
    <>
      <ModalHeader className="flex flex-col gap-1">Crear Usuario</ModalHeader>
      <ModalBody>
        <div className="flex justify-evenly mb-4">
          <label className="self-center flex flex-col items-center text-sm">
            <input
              type="file"
              accept="image/*"
              className="hidden"
              onChange={v => onChange(v.target.files[0], 'photo')}
            />
            <Avatar
              className="w-20 h-20 text-primaryDark"
              src={form.photoPreview}
              showFallback
            />
            <span>Foto del usuario</span>
          </label>
          <label className="self-center flex flex-col items-center text-sm">
            <input
              type="file"
              accept="image/*"
              className="hidden"
              onChange={v => onChange(v.target.files[0], 'documentPhoto')}
            />
            <Avatar
              className="w-20 h-20 text-primaryDark"
              src={form.documentPhotoPreview}
              showFallback
            />
            <span>Identificación</span>
          </label>
        </div>
        <Input
          classNames={{ inputWrapper: 'border-1 h-12 bg-white' }}
          label="Nombres"
          variant="bordered"
          value={form.name}
          onValueChange={v => onChange(v, 'name')}
        />
        <Input
          classNames={{ inputWrapper: 'border-1 h-12 bg-white' }}
          label="Apellidos"
          variant="bordered"
          value={form.lastname}
          onValueChange={v => onChange(v, 'lastname')}
        />
        <Select
          isDisabled
          label="Tipo de Usuario"
          variant="bordered"
          classNames={{ trigger: 'border-1 h-12 bg-white' }}
          disallowEmptySelection
          selectedKeys={form.level_id ? [form.level_id]:[]}
          onSelectionChange={v => onChange(v.currentKey,'level_id')}
        >
        {levelSelect.map((status) => (
          <SelectItem key={status.value} value={status.value}>
            { status.label }
          </SelectItem>
        ))}
      </Select>
        <Input
          classNames={{ inputWrapper: 'border-1 h-12 bg-white' }}
          label="ID / Pasaporte"
          variant="bordered"
          value={form.document}
          onValueChange={v => onChange(v, 'document')}
        />
        <Input
          classNames={{ inputWrapper: 'border-1 h-12 bg-white' }}
          label="País de origen"
          variant="bordered"
          value={form.country}
          onValueChange={v => onChange(v, 'country')}
        />
        <Input
          classNames={{ inputWrapper: 'border-1 h-12 bg-white' }}
          label="Dirección"
          variant="bordered"
          value={form.address}
          onValueChange={v => onChange(v, 'address')}
        />
        <Input
          classNames={{ inputWrapper: 'border-1 h-12 bg-white' }}
          label="Teléfono"
          variant="bordered"
          value={form.phone}
          onValueChange={v => onChange(v, 'phone')}
        />
        <Input
          classNames={{ inputWrapper: 'border-1 h-12 bg-white' }}
          label="Correo electrónico"
          variant="bordered"
          value={form.email}
          onValueChange={v => onChange(v, 'email')}
        />

        <Input
          classNames={{ inputWrapper: 'border-1 h-12 bg-white' }}
          label="Contraseña"
          variant="bordered"
          type="password"
          value={form.password}
          onValueChange={v => onChange(v, 'password')}
        />
        <Input
          classNames={{ inputWrapper: 'border-1 h-12 bg-white' }}
          type="date"
          label="Fecha inicio actividades"
          placeholder=" "
          variant="bordered"
          value={form.start_date}
          onValueChange={v => onChange(v, 'start_date')}
        />

        <Divider className="mt-2" />

        <p className="text-md font-medium text-blue-800">Datos Bancarios</p>
        <Input
          classNames={{ inputWrapper: 'border-1 h-12 bg-white' }}
          label="Cuenta Bancaria"
          variant="bordered"
          value={form.bankNumber}
          onValueChange={v => onChange(v, 'bankNumber')}
        />
        <Input
          classNames={{ inputWrapper: 'border-1 h-12 bg-white' }}
          label="Código SWIFT / BIC"
          variant="bordered"
          value={form.bankName}
          onValueChange={v => onChange(v, 'bankName')}
        />
        <Input
          classNames={{ inputWrapper: 'border-1 h-12 bg-white' }}
          label="ACH routing number(ABA)"
          variant="bordered"
          value={form.bankRoute}
          onValueChange={v => onChange(v, 'bankRoute')}
        />
      </ModalBody>
      <ModalFooter className="justify-evenly">
        <Button variant="light" onPress={onClose}>Cerrar</Button>
        <Button color="primary" onPress={submit}>Guardar</Button>
      </ModalFooter>
    </>
  )
}

const validateEmail = (value) => {
  value = value.trim();
  const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return value.match(validRegex);
}

const useFetch = () => {
  const onError = (msg) => toast.error(msg);

  const isValidForm = (cleaner) => {
    const onError = (msg) => {
      toast.error(msg);
      return false;
    }

    if (!cleaner.email)
      return onError('El correo es obligatorio');

    if (!validateEmail(cleaner.email)) {
      return onError('El correo no es válido');
    }

    if (!cleaner.name)
      return onError('El nombre es obligatorio');

    if (!cleaner.lastname)
      return onError('El apellido es obligatorio');

    if (!cleaner.document)
      return onError('El documento de identidad es obligatorio');

    if (!cleaner.phone)
      return onError('El teléfono es obligatorio');

    if (!cleaner.country)
      return onError('El país es obligatorio');

    if (!cleaner.address)
      return onError('La dirección es obligatoria');

    if (!cleaner.bankNumber)
      return onError('La Cuenta Bancaria es obligatoria');

    if (!cleaner.bankName)
      return onError('El Código SWIFT / BIC es obligatorio');

    if (!cleaner.bankRoute)
      return onError('ACH routing number(ABA) es obligatorio');

    return true;
  }

  const onSubmit = async (cleaner) => {
  if (!isValidForm(cleaner)) return;
    try {
      await CleanersService.create(cleaner);
      toast.success('¡Usuario creado correctamente!');
      return true;

    } catch (error) {
      onError(String(error));
      return false;
    }
  }

  return { onSubmit }
}

export default ModalCreate;
