/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Input, ModalHeader, ModalBody, ModalFooter, Button, Listbox, ListboxItem, Tooltip } from '@nextui-org/react';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import toast from 'react-hot-toast';
import { CleanersService, OrdersService, StoresService } from '../../services';
import { clone } from '../../utils';
import moment from 'moment';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const startTimeValidator = (start, end) => {
    if (!start && !end) return false;
    if (!start && end) return 'Debe ingresar la hora';

    const startTime = moment(start, 'HH:mm');
    if (!startTime.isValid()) return 'La hora no es válida';

    // Hora de inicio es válido y no hay hora de salida
    if (!end) return false;
    const endTime = moment(end, 'HH:mm');
    if (!endTime.isValid()) return false;

    if (startTime >= endTime) return 'La salida debe ser posterior al inicio';

    return false;
}

const endTimeValidator = (start, end) => {
    if (!start || !end) return false;

    const startTime = moment(start, 'HH:mm');
    const endTime = moment(end, 'HH:mm');
    if (!endTime.isValid()) return 'La hora no es válida';

    if (startTime >= endTime) return 'La salida debe ser posterior al inicio';

    return false;
}

const ModalCreateOrderTemplate = ({ order, onSuccess, onClose }) => {
    const isEditing = !!order;
    const { clearCleaners, clearStores, cleaners, onSubmit, searchCleaner, searchStore, stores } = useFetch(isEditing);

    const [form, setForm] = useState({
        cleaner_id: '',
        cleaner_name: '',
        details: [],
        _searchStore: '',
        _searchCleaner: '',
    });

    const [storeDetails, setStoreDetails] = useState({
        store_id: '',
        store_name: '',
        observations: '',
        schedules: [],
    });

    const [selectedCleaner, setSelectedCleaner] = useState('');
    const [selectedStore, setSelectedStore] = useState('');

    const daysOfWeek = [
        { name: 'Lunes', value: 1 },
        { name: 'Martes', value: 2 },
        { name: 'Miércoles', value: 3 },
        { name: 'Jueves', value: 4 },
        { name: 'Viernes', value: 5 },
        { name: 'Sábado', value: 6 },
        { name: 'Domingo', value: 7 }
    ];

    // Estado para los valores de entrada de tiempo
    const [timeInputs, setTimeInputs] = useState({
        Monday: { start: '', end: '' },
        Tuesday: { start: '', end: '' },
        Wednesday: { start: '', end: '' },
        Thursday: { start: '', end: '' },
        Friday: { start: '', end: '' },
        Saturday: { start: '', end: '' },
        Sunday: { start: '', end: '' },
    });

    const handleAddStore = () => {
        if (!storeDetails.store_id) return toast.error('Por favor selecciona una tienda.');
        if (!storeDetails.schedules.length) return toast.error('Debes agregar al menos un horario.');
        if (storeDetails.schedules.some(x => !!x.start_time && x.start_time === x.end_time))
            return toast.error('Debe corregir los errores en la plantilla');

        setForm((prev) => ({
            ...prev,
            details: [...prev.details, storeDetails],
        }));

        // Resetear los detalles de la tienda y los inputs de tiempo después de añadirla
        setStoreDetails({
            store_id: '',
            store_name: '',
            observations: '',
            schedules: [],
        });

        setTimeInputs({
            Monday: { start: '', end: '' },
            Tuesday: { start: '', end: '' },
            Wednesday: { start: '', end: '' },
            Thursday: { start: '', end: '' },
            Friday: { start: '', end: '' },
            Saturday: { start: '', end: '' },
            Sunday: { start: '', end: '' },
        });

        setSelectedStore('');  // Limpiar la tienda seleccionada
    };

    /*
    const handleAddSchedule = (day, start, end) => {
        const dayValue = daysOfWeek.find(d => d.name === day)?.value;
        console.log('dayValue: ', dayValue)
        if (!dayValue) return;

        // Validación: La hora de salida debe ser mayor a la de inicio
        if (start && end && moment(end, 'HH:mm').isSameOrBefore(moment(start, 'HH:mm'))) {
            return;
            // Limpiar el campo de la hora de salida si es menor que la hora de inicio
            // setTimeInputs(prev => ({
            //     ...prev,
            //     [day]: { ...prev[day], end: '' }
            // }));
            // return toast.error("La hora de salida debe ser mayor que la hora de inicio.");
        }

        // Validación: No debe haber superposición de horas en un mismo día en ninguna tienda
        const hasOverlap = form.details.some(detail => {
            return detail.schedules.some(schedule => {
                return (
                    schedule.day_of_week === dayValue &&
                    (
                        moment(start, 'HH:mm').isBefore(moment(schedule.end_time, 'HH:mm')) &&
                        moment(end, 'HH:mm').isAfter(moment(schedule.start_time, 'HH:mm'))
                    )
                );
            });
        });
        if (hasOverlap) {
            return;
            // Limpiar el campo de la hora de salida
            // setTimeInputs(prev => ({
            //     ...prev,
            //     [day]: { ...prev[day], end: '' }
            // }));
            // return toast.error("El horario asignado se superpone con otro horario para el mismo día.");
        }

        // Si pasa las validaciones, agregar o actualizar el horario
        setTimeInputs(prev => ({
            ...prev,
            [day]: {
                start: start !== undefined ? start : prev[day]?.start,
                end: end !== undefined ? end : prev[day]?.end,
            },
        }));

        setStoreDetails(prev => {
            const updatedSchedules = [...prev.schedules];
            const existingScheduleIndex = updatedSchedules.findIndex(schedule => schedule.day_of_week === dayValue);

            if (existingScheduleIndex !== -1) {
                updatedSchedules[existingScheduleIndex] = {
                    ...updatedSchedules[existingScheduleIndex],
                    start_time: start || updatedSchedules[existingScheduleIndex].start_time,
                    end_time: end || updatedSchedules[existingScheduleIndex].end_time,
                };
            } else if (start && end) {
                updatedSchedules.push({ day_of_week: dayValue, start_time: start, end_time: end });
            }

            return { ...prev, schedules: updatedSchedules };
        });
    };
    */
    const handleAddSchedule = (day, start, end) => {
        const dayValue = daysOfWeek.find(d => d.name === day)?.value;
        if (!dayValue) return;
    
        // Validación: La hora de salida debe ser mayor a la de inicio
        if (start && end && moment(end, 'HH:mm').isSameOrBefore(moment(start, 'HH:mm'))) {
            return;
        }
    
        // Validación: No debe haber superposición de horas en un mismo día en ninguna tienda
        const hasOverlap = form.details.some(detail => {
            return detail.schedules.some(schedule => {
                return (
                    schedule.day_of_week === dayValue &&
                    (
                        moment(start, 'HH:mm').isBefore(moment(schedule.end_time, 'HH:mm')) &&
                        moment(end, 'HH:mm').isAfter(moment(schedule.start_time, 'HH:mm'))
                    )
                );
            });
        });
        if (hasOverlap) {
            return;
        }
    
        // Formatear `start` y `end` a "HH:mm:ss"
        const formattedStart = start ? moment(start, 'HH:mm').format('HH:mm:ss') : '';
        const formattedEnd = end ? moment(end, 'HH:mm').format('HH:mm:ss') : '';
    
        setStoreDetails(prev => {
            const updatedSchedules = [...prev.schedules];
            const existingScheduleIndex = updatedSchedules.findIndex(schedule => schedule.day_of_week === dayValue);
    
            // Si ambos tiempos están vacíos, eliminar el horario de este día
            if (!formattedStart && !formattedEnd) {
                if (existingScheduleIndex !== -1) {
                    updatedSchedules.splice(existingScheduleIndex, 1);
                }
            } else if (formattedStart && formattedEnd) {
                // Agregar o actualizar el horario en `storeDetails`
                if (existingScheduleIndex !== -1) {
                    updatedSchedules[existingScheduleIndex] = {
                        ...updatedSchedules[existingScheduleIndex],
                        start_time: formattedStart,
                        end_time: formattedEnd,
                    };
                } else {
                    updatedSchedules.push({ day_of_week: dayValue, start_time: formattedStart, end_time: formattedEnd });
                }
            }
    
            // Ordenar horarios por `day_of_week`
            updatedSchedules.sort((a, b) => a.day_of_week - b.day_of_week);
    
            return { ...prev, schedules: updatedSchedules };
        });
    
        // Actualizar `timeInputs` para reflejar los cambios
        setTimeInputs(prev => ({
            ...prev,
            [day]: { start, end },
        }));
    };        

    /*
    const handleEditSchedule = (index) => {
        const _timeInput = {
            'Monday': { start: '', end: '' },
            'Tuesday': { start: '', end: '' },
            'Wednesday': { start: '', end: '' },
            'Thursday': { start: '', end: '' },
            'Friday': { start: '', end: '' },
            'Saturday': { start: '', end: '' },
            'Sunday': { start: '', end: '' },

            'Lunes': { start: '', end: '' },
            'Martes': { start: '', end: '' },
            'Miércoles': { start: '', end: '' },
            'Jueves': { start: '', end: '' },
            'Viernes': { start: '', end: '' },
            'Sábado': { start: '', end: '' },
            'Domingo': { start: '', end: '' },
        };

        form.details[index].schedules?.forEach(schedule => {
            const weekDay = daysOfWeek.find(d => d.value === schedule.day_of_week)?.name;
            _timeInput[weekDay] = {
                day_of_week: schedule.day_of_week,
                start: schedule.start_time,
                end: schedule.end_time,
            };
        });
        setStoreDetails({
            ...storeDetails,
            store_id: form.details[index].store_id,
            store_name: form.details[index].store_name,
            observations: form.details[index].observations,
        });
        setTimeInputs(_timeInput);
        handleRemoveSchedule(index);
    }
    */
    const handleEditSchedule = (index) => {
        const _timeInput = {
            'Monday': { start: '', end: '' },
            'Tuesday': { start: '', end: '' },
            'Wednesday': { start: '', end: '' },
            'Thursday': { start: '', end: '' },
            'Friday': { start: '', end: '' },
            'Saturday': { start: '', end: '' },
            'Sunday': { start: '', end: '' },
    
            'Lunes': { start: '', end: '' },
            'Martes': { start: '', end: '' },
            'Miércoles': { start: '', end: '' },
            'Jueves': { start: '', end: '' },
            'Viernes': { start: '', end: '' },
            'Sábado': { start: '', end: '' },
            'Domingo': { start: '', end: '' },
        };
    
        // Rellenar _timeInput con los horarios actuales de la tienda seleccionada
        form.details[index].schedules?.forEach(schedule => {
            const weekDay = daysOfWeek.find(d => d.value === schedule.day_of_week)?.name;
            _timeInput[weekDay] = {
                day_of_week: schedule.day_of_week,
                start: schedule.start_time,
                end: schedule.end_time,
            };
        });
    
        // Configurar los detalles de la tienda junto con los horarios actuales
        setStoreDetails({
            store_id: form.details[index].store_id,
            store_name: form.details[index].store_name,
            observations: form.details[index].observations,
            schedules: form.details[index].schedules, // Asignar los horarios actuales
        });
    
        // Asignar los horarios a timeInputs
        setTimeInputs(_timeInput);
    
        // Eliminar temporalmente el ítem del formulario, ya que se va a editar
        handleRemoveSchedule(index);
    };
    

    const handleRemoveSchedule = (index) => {
        setForm((prev) => {
            const updatedSchedules = [...prev.details];
            updatedSchedules.splice(index, 1);
            return { ...prev, details: updatedSchedules };
        });
    };

    const onSelectCleaner = (item) => {
        setSelectedCleaner(item);
        setForm((prev) => ({
            ...prev,
            cleaner_id: item,
            cleaner_name: cleaners.find(c => c.id === +item)?.person?.fullName,
            _searchCleaner: '',  // Limpiar búsqueda para cerrar el select
        }));
    };

    const onSelectStore = (item) => {
        setSelectedStore(item);
        setStoreDetails((prev) => ({
            ...prev,
            store_id: item,
            store_name: stores.find(s => s.id === +item)?.name,
        }));
        setForm((prev) => ({
            ...prev,
            _searchStore: '',  // Limpiar búsqueda para cerrar el select
        }));
    };

    const handleSubmit = async () => {
        const isSuccess = await onSubmit(clone(form));
        if (isSuccess) onSuccess();
    };

    useEffect(() => {
        if (isEditing) {
            setForm({
                ...form,
                order_template_id: order.id,
                cleaner_id: order.cleaner_id,
                cleaner_name: order.cleaner?.person?.fullName,
                details: order.orderTemplateDetails?.map(row => ({
                    store_id: row.store_id,
                    store_name: row.store?.name,
                    observations: row.observations,
                    schedules: row?.orderTemplateDetailSchedules?.map(schedule => ({
                        day_of_week: schedule.day_of_week,
                        start_time: schedule.start_time,
                        end_time: schedule.end_time,
                    })),
                })),
            });
        }
    }, []);

    return (
        <>
            <ModalHeader className="flex flex-col gap-1">
                {isEditing ? 'Editar Plantilla de Orden' : 'Crear Plantilla de Orden'}
            </ModalHeader>
            <ModalBody>
                {/* Selección de cleaner */}
                <div className="relative">
                    <Input
                        classNames={{ inputWrapper: 'border-1 h-12' }}
                        label="Limpiador"
                        placeholder={form.cleaner_name}
                        variant="bordered"
                        isClearable
                        onClear={clearCleaners}
                        value={form._searchCleaner}
                        onValueChange={(v) => {
                            setForm({ ...form, _searchCleaner: v });
                            searchCleaner(v);  // Activar búsqueda con cada cambio
                        }}
                    />
                    <div className={classNames(cleaners.length && form._searchCleaner ? '' : 'hidden', 'w-full border-small px-1 py-2 rounded-small absolute z-20 bg-white')}>
                        <Listbox
                            aria-label="Limpiadores"
                            variant="flat"
                            disallowEmptySelection
                            selectionMode="single"
                            selectedKeys={selectedCleaner ? [selectedCleaner] : []}  // Selección activa
                            onSelectionChange={(v) => onSelectCleaner(v.currentKey)}
                            classNames={{ base: 'max-h-48 overflow-auto' }}
                        >
                            {cleaners.length ? (
                                cleaners.map((cleaner) => (
                                    <ListboxItem key={cleaner.id}>{cleaner?.person?.name} {cleaner?.person?.lastname}</ListboxItem>
                                ))
                            ) : (
                                ""
                            )}
                        </Listbox>
                    </div>
                </div>

                {/* Selección de tienda */}
                <div className="relative">
                    <Input
                        classNames={{ inputWrapper: 'border-1 h-12' }}
                        label="Tiendas"
                        placeholder={storeDetails.store_name}
                        variant="bordered"
                        isClearable
                        onClear={() => {
                            clearStores();
                            setStoreDetails({ ...storeDetails, store_id: '' });
                        }}
                        value={form._searchStore}
                        onValueChange={(v) => {
                            setForm({ ...form, _searchStore: v });
                            searchStore(v);  // Activar búsqueda con cada cambio
                        }}
                    />
                    <div className={classNames(stores.length && form._searchStore ? '' : 'hidden', 'w-full border-small px-1 py-2 rounded-small absolute z-20 bg-white')}>
                        <Listbox
                            aria-label="Tiendas"
                            variant="flat"
                            disallowEmptySelection
                            selectionMode="single"
                            selectedKeys={selectedStore ? [selectedStore] : []}  // Selección activa
                            onSelectionChange={(v) => onSelectStore(v.currentKey)}
                            classNames={{ base: 'max-h-48 overflow-auto' }}
                        >
                            {stores.length ? (
                                stores.map((store) => (
                                    <ListboxItem key={store.id}>{store?.name}</ListboxItem>
                                ))
                            ) : (
                                ""
                            )}
                        </Listbox>
                    </div>
                </div>

                {/* Observaciones */}
                <Input
                    classNames={{ inputWrapper: 'border-1 h-12' }}
                    label="Observaciones"
                    variant="bordered"
                    value={storeDetails.observations}
                    onValueChange={(v) => setStoreDetails({ ...storeDetails, observations: v })}
                />

                {/* Horarios */}
                <div>
                    <p className="text-lg font-semibold text-primary mb-0">Programar limpieza</p>
                    <span className="text-sm">Seleccione el día y hora a trabajar</span>
                </div>
                <div className="schedule-section container m-auto grid grid-cols-4 md:grid-cols-5 lg:grid-cols-7 gap-4">
                    {daysOfWeek.map((day) => {
                        const startValidator = startTimeValidator(timeInputs[day.name]?.start, timeInputs[day.name]?.end);
                        const endValidator = endTimeValidator(timeInputs[day.name]?.start, timeInputs[day.name]?.end);

                        return(
                            <div key={day.value} className="day-inputs flex flex-col gap-2">
                                <label className="text-sm font-medium bg-gray-100 text-center py-1.5 rounded-md border-1 border-gray-300">
                                    {day.name}
                                </label>
                                {/* 
                                    <Input
                                        type="time"
                                        label="Inicio"
                                        placeholder=" "
                                        variant="bordered"
                                        classNames={{ inputWrapper: 'p-1 border-1' }}
                                        radius="sm"
                                        value={timeInputs[day.name]?.start || ''}
                                        onValueChange={(v) => {
                                            setTimeInputs((prev) => ({
                                                ...prev,
                                                [day.name]: { ...prev[day.name], start: v }
                                            }));
                                            if (v && timeInputs[day.name]?.end) { // Verifica si ambos tiempos están definidos
                                                handleAddSchedule(day.name, v, timeInputs[day.name].end);
                                            }
                                        }}
                                        isInvalid={!!startValidator}
                                        errorMessage={startValidator}
                                    />
                                    <Input
                                        type="time"
                                        label="Salida"
                                        placeholder=" "
                                        variant="bordered"
                                        classNames={{ inputWrapper: 'p-1 border-1' }}
                                        radius="sm"
                                        value={timeInputs[day.name]?.end || ''}
                                        onValueChange={(v) => {
                                            setTimeInputs((prev) => ({
                                                ...prev,
                                                [day.name]: { ...prev[day.name], end: v }
                                            }));
                                            if (v && timeInputs[day.name]?.start) { // Verifica si ambos tiempos están definidos
                                                handleAddSchedule(day.name, timeInputs[day.name].start, v);
                                            }
                                        }}
                                        isInvalid={!!endValidator}
                                        errorMessage={endValidator}
                                    />
                                */}
                                <Input
                                    type="time"
                                    label="Inicio"
                                    placeholder=" "
                                    variant="bordered"
                                    classNames={{ inputWrapper: 'p-1 border-1' }}
                                    radius="sm"
                                    value={timeInputs[day.name]?.start || ''}
                                    onValueChange={(v) => {
                                        const newStart = v || ''; // Asigna una cadena vacía si `v` es `undefined`
                                        setTimeInputs((prev) => ({
                                            ...prev,
                                            [day.name]: { ...prev[day.name], start: newStart }
                                        }));
                                        // Llama a `handleAddSchedule` sin importar si `newStart` o `end` están vacíos
                                        handleAddSchedule(day.name, newStart, timeInputs[day.name]?.end || '');
                                    }}
                                    isInvalid={!!startValidator}
                                    errorMessage={startValidator}
                                />
                                <Input
                                    type="time"
                                    label="Salida"
                                    placeholder=" "
                                    variant="bordered"
                                    classNames={{ inputWrapper: 'p-1 border-1' }}
                                    radius="sm"
                                    value={timeInputs[day.name]?.end || ''}
                                    onValueChange={(v) => {
                                        const newEnd = v || ''; // Asigna una cadena vacía si `v` es `undefined`
                                        setTimeInputs((prev) => ({
                                            ...prev,
                                            [day.name]: { ...prev[day.name], end: newEnd }
                                        }));
                                        // Llama a `handleAddSchedule` sin importar si `start` o `newEnd` están vacíos
                                        handleAddSchedule(day.name, timeInputs[day.name]?.start || '', newEnd);
                                    }}
                                    isInvalid={!!endValidator}
                                    errorMessage={endValidator}
                                />
                                
                            </div>
                        )
                    })}
                </div>

                <div className="mx-auto">
                    <Button
                        className="font-bold text-primary"
                        variant="light"
                        onPress={handleAddStore}
                    >
                        CARGAR
                    </Button>
                </div>

                {/* Mostrar tiendas añadidas */}
                {/* {form.details.map((detail, index) => (
                    <div key={index} className="detail-item">
                        <p>Tienda ID: {detail.store_id} - {detail.observations}</p>
                        <ul>
                            {detail.schedules.map((schedule, idx) => (
                                <li key={idx}>{schedule.day_of_week}: {schedule.start_time} - {schedule.end_time}</li>
                            ))}
                        </ul>
                    </div>
                ))} */}

                <div>
                    <div className="grid grid-cols-10 gap-2 bg-primary text-white text-sm -mx-6 p-2">
                        <span className="col-span-2">Tienda</span>
                        <span>Lunes</span>
                        <span>Martes</span>
                        <span>Miércoles</span>
                        <span>Jueves</span>
                        <span>Viernes</span>
                        <span>Sábado</span>
                        <span>Domingo</span>
                    </div>

                    {/* Mostrar tiendas añadidas */}
                    {form.details.map((detail, index) => {
                        return (
                            <div
                                key={index}
                                className="grid grid-cols-10 gap-2 text-sm -mx-6 p-2"
                            >
                                <div>
                                    <p className="font-semibold">{ detail.store_name }</p>
                                    <p>{detail.observations}</p>
                                </div>
                                {detail.schedules.map((schedule, idx) => {
                                    // Obtener el nombre del día basado en el value de day_of_week
                                    // const dayName = daysOfWeek.find(d => d.value === schedule.day_of_week)?.name || 'Día desconocido';

                                    // Busca la ubicación en la celda en base al día de la semana asignado
                                    const gridColumnStart = schedule.day_of_week + 2;
                                    const gridColumnEnd = gridColumnStart + 1;

                                    return (
                                        <div
                                            key={idx}
                                            style={{ gridColumnStart, gridColumnEnd }}
                                        >
                                            <p>{ moment(schedule.start_time, 'HH:mm').format('h:mm a') }</p>
                                            <p>{ moment(schedule.end_time, 'HH:mm').format('h:mm a') }</p>
                                        </div>
                                    );
                                })}
                                <div className="flex gap-2" style={{ gridColumnStart: 10, gridColumnEnd: 11 }}>
                                    <Tooltip content="Editar">
                                        <IconEdit onClick={() => handleEditSchedule(index)} />
                                    </Tooltip>
                                    <Tooltip content="Eliminar">
                                        <IconTrash onClick={() => handleRemoveSchedule(index)} />
                                    </Tooltip>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </ModalBody>
            <ModalFooter>
                <Button style={{ width: "auto", maxWidth: "150px" }} onPress={onClose}>Cancelar</Button>
                <Button style={{ width: "auto", maxWidth: "150px" }} onPress={handleSubmit}>{isEditing ? 'Actualizar' : 'Crear'}</Button>
            </ModalFooter>
        </>
    );
};

const useFetch = (isEditing) => {
    const [stores, setStores] = useState([]);
    const [cleaners, setCleaners] = useState([]);
    const debounceTime = 500;
    const debounce = useRef();

    const onError = (msg) => toast.error(msg);

    const getStores = async (search) => {
        try {
            const res = await StoresService.findAll({ search });
            setStores(res.data);
        } catch (error) {
            onError(String(error));
        }
    };

    const getCleaners = async (search) => {
        try {
            const res = await CleanersService.findAllForSelect({ search, isPaginated: 1, withoutOrderTemplate: true });
            setCleaners(res.data);
        } catch (error) {
            onError(String(error));
        }
    };

    const searchStore = (value) => {
        if (debounce.current) clearTimeout(debounce.current);
        debounce.current = setTimeout(() => {
            getStores(value);
        }, debounceTime);
    };

    const searchCleaner = (value) => {
        if (debounce.current) clearTimeout(debounce.current);
        debounce.current = setTimeout(() => {
            getCleaners(value);
        }, debounceTime);
    };

    return {
        cleaners,
        clearCleaners: () => setCleaners([]),
        clearStores: () => setStores([]),
        onSubmit: async (data) => {
            console.log('data: ',data)

            // Validar que data.details no esté vacío
            if (!data.details || data.details.length === 0) {
                toast.error("Debes agregar al menos un horario.");
                return false;
            }

            try {
                const response = isEditing ? await OrdersService.editOrderTemplate(data) : await OrdersService.createOrderTemplate(data);
                toast.success(`Plantilla ${isEditing ? 'editada' : 'creada'} con éxito`);
                return true;
            } catch (error) {
                onError(String(error));
                return false;
            }
        },
        searchCleaner,
        searchStore,
        stores,
    };
};

export default ModalCreateOrderTemplate;
