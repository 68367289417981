/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Input, ModalHeader, ModalBody, ModalFooter, Button, Listbox, ListboxItem } from '@nextui-org/react';
import toast from 'react-hot-toast';
import { GoogleService, StoresService } from '../../services';
import { CurrencyInput } from '../../components';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const google = window.google;

const defaultLatLng = {
	latitude: 29.7604315123649,
	longitude: -95.36981707226198,
};

const ModalEdit = ({ store, onSuccess, onClose }) => {
  const mapRef = useRef();
  const { getCoords, onSubmit, predictions, search } = useFetch();
  const [form, setForm] = useState({
		...store,
    __address: '',
		latitude: store?.latitude ? Number(store.latitude) : defaultLatLng.latitude,
		longitude: store?.longitude ? Number(store.longitude) : defaultLatLng.longitude,
  });

  const setMap = async () => {
    mapRef.current = new google.maps.Map(document.getElementById('store-location-map'), {
      zoom: 13,
      center: new google.maps.LatLng(
        store?.latitude ? Number(store.latitude) : defaultLatLng.latitude,
        store?.longitude ? Number(store.longitude) : defaultLatLng.longitude
      ),
      draggable: true,
      zoomControl: true,
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false,
      scaleControl: false,
      rotateControl: false
    });

    const marker = new google.maps.Marker({
      position: mapRef.current.getCenter(),
      map: mapRef.current,
      title: '',
      animation: google.maps.Animation.DROP,
      draggable: true,
    });

    google.maps.event.addListener(mapRef.current, 'center_changed', () => {
      const center = mapRef.current.getCenter();
      marker.setPosition(center);
    });

    google.maps.event.addListener(marker, 'dragend', (e) => {
      mapRef.current.setCenter({ lat: e.latLng.lat(), lng: e.latLng.lng() });
      setForm(s => ({ ...s, latitude: e.latLng.lat(), longitude: e.latLng.lng() }));
    });
  }

	const setLatLng = ({ latitude, longitude }) => {
    mapRef.current.setCenter({ lat: latitude, lng: longitude });
    setForm(s => ({ ...s, latitude, longitude }));
	}

  /*
  const onChange = (value, target) => {
    setForm(s => ({ ...s, [target]: value }));

    if (target === '__address' && !!value) {
      search(value);
    }
  }
  */
  const calculateValueDay = (valueCleaner) => {
    if (valueCleaner === '') return '';
    return (parseFloat(valueCleaner) / 30).toFixed(2); // Redondear a dos decimales
  };

  const onChange = (value, target) => {
    let newValue = value;

    if (target === '__address') {
      search(value);
    }

    if (target === 'value_cleaner') {
      const calculatedValueDay = calculateValueDay(newValue);
      setForm(prevState => ({
        ...prevState,
        [target]: newValue,
        value_day: calculatedValueDay
      }));
    } else {
      setForm(prevState => ({
        ...prevState,
        [target]: newValue
      }));
    }
  };

  const onSelectAddress = async (value) => {
    const item = predictions.find(x => x.place_id === value?.currentKey);
    onChange(item?.description ?? '', 'address');
    getCoords(value?.currentKey)
      .then(setLatLng)
      .catch(console.log);
  }

  const submit = async () => {
    const isSuccess = await onSubmit(form);
    if (isSuccess) onSuccess();
  }

  useEffect(() => {
    setMap();
  }, []);

  return (
    <>
      <ModalHeader>Editar Tienda</ModalHeader>
      <ModalBody>
        <div className="grid grid-cols-2 gap-4 items-start">
          <p className="font-semibold">Datos generales</p>
          <p className="font-semibold">Costo de limpieza</p>

          <div className="flex flex-col gap-4">
            <Input
              classNames={{ inputWrapper: 'border-1 h-12' }}
              label="Nombre de la tienda"
              labelPlacement="outside"
              placeholder=" "
              variant="bordered"
              value={form.name}
              onValueChange={v => onChange(v, 'name')}
            />
            <Input
              classNames={{ inputWrapper: 'border-1 h-12' }}
              label="Número"
              labelPlacement="outside"
              placeholder=" "
              variant="bordered"
              value={form.number}
              onValueChange={v => onChange(v, 'number')}
            />
            <Input
              classNames={{ inputWrapper: 'border-1 h-12' }}
              label="Teléfono"
              labelPlacement="outside"
              placeholder=" "
              variant="bordered"
              value={form.phone}
              onValueChange={v => onChange(v, 'phone')}
            />
            <Input
              classNames={{ inputWrapper: 'border-1 h-12' }}
              label="Dirección"
              labelPlacement="outside"
              placeholder=" "
              variant="bordered"
              value={form.address}
              onValueChange={v => {
                onChange(v, 'address');
                onChange(v, '__address');
              }}
            />

            <div className="relative">
              <div className={classNames(predictions.length ? '':'hidden', 'w-full border-small px-1 py-2 rounded-small absolute z-10 bg-white')}>
                <Listbox
                  aria-label="Direcciones del mapa"
                  variant="flat"
                  disallowEmptySelection
                  selectionMode="single"
                  selectedKeys={new Set(["text"])}
                  onSelectionChange={onSelectAddress}
                  classNames={{ base: 'max-h-48 overflow-auto' }}
                >
                  {predictions.map(item => (
                    <ListboxItem key={item.place_id}>{ item.description }</ListboxItem>
                  ))}
                </Listbox>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <CurrencyInput
              label={
                <>
                  <label>Valor tienda $</label>
                  <small>(máximo)</small>
                </>
              }
              placeholder=" "
              value={form.value_store}
              onValueChange={v => onChange(v, 'value_store')}
            />
            <CurrencyInput
              label={
                <>
                  <label>Valor</label>
                  <label>limpiador $</label>
                </>
              }
              placeholder=" "
              value={form.value_cleaner}
              onValueChange={v => onChange(v, 'value_cleaner')}
            />
            <CurrencyInput
              label={
                <>
                  <label>Valor</label>
                  <label>supervisor $</label>
                </>
              }
              placeholder=" "
              value={form.value_supervisor}
              onValueChange={v => onChange(v, 'value_supervisor')}
            />
            <CurrencyInput
              label={
                <>
                  <label>Valor día $</label>
                  <small>(Valor limpiador/30)</small>
                </>
              }
              placeholder=" "
              value={form.value_day}
              disabled={true}
            />
            <CurrencyInput
              label={
                <>
                  <label>Valor hora $</label>
                  <small>(Valor día/ horas)</small>
                </>
              }
              placeholder=" "
              value={form.value_hour}
              onValueChange={v => onChange(v, 'value_hour')}
            />
            <div />
          </div>
        </div>

        <div id="store-location-map" className="w-full h-60" />
      </ModalBody>
      <ModalFooter>
        <Button variant="light" onPress={onClose}>Cerrar</Button>
        <Button color="primary" onPress={submit}>Guardar</Button>
      </ModalFooter>
    </>
  )
}

const useFetch = () => {

  const [predictions, setPredictions] = useState([]);
  const debounceTime = 500;
  const debounce = useRef();

  const onError = (msg) => toast.error(msg);

  const isValidForm = (store) => {
    const onError = (msg) => {
      toast.error(msg);
      return false;
    }

    if (!store.name)
      return onError('El nombre es obligatorio');

    if (!store.number)
      return onError('El número es obligatorio');

    if (!store.phone)
      return onError('El teléfono es obligatorio');

    if (!store.address)
      return onError('La dirección es obligatoria');

    if (!store.value_store)
      return onError('El valor de la tienda es obligatorio');

    if (!store.value_cleaner)
      return onError('El valor del limpiador es obligatorio');

    if (!store.value_supervisor)
      return onError('El valor supervisor es obligatorio');

    if (!store.value_day)
      return onError('El valor diario es obligatorio');

    if (!store.value_hour)
      return onError('El valor por hora es obligatorio');

    return true;
  }

  const getAutomplete = async (input) => {
    try {
      const res = await GoogleService.autocomplete({ input });
      setPredictions(res.predictions);

    } catch (error) {
      onError(String(error));
    }
  }

  const getCoords = async (place_id) => {
    setPredictions([]);
    try {
      const res = await GoogleService.geoCode({ place: place_id });
      return res?.data || null;

    } catch (error) {
      onError(String(error));
    }
  }

  const onSubmit = async (store) => {
    if (!isValidForm(store)) return;

    try {
      await StoresService.update(store);
      toast.success('Tienda actualizada con éxito');
      return true;

    } catch (error) {
      onError(String(error));
      return false;
    }
  }

  const search = (value) => {
    if (debounce.current) clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      getAutomplete(value);
    }, debounceTime);
  }

  return {
    getCoords,
    onSubmit,
    predictions,
    search,
  }
}

export default ModalEdit;
