/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { SelectItem, Select, Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, User, Chip, Tooltip, Pagination, Input, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Spinner, Avatar, Divider, Image } from '@nextui-org/react';
import { IconUser, IconPlus, IconDownload, IconEye, IconMapPin, IconSearch, IconTrash, IconUserCheck, IconUserPause, IconPhone, IconMail, IconX, IconHistory } from '@tabler/icons-react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { Constants, fromPhotos } from '../../utils';
import { CleanersService } from '../../services';
import ModalEdit from './edit-cleaner';
import ModalCreate from './create-cleaner';

const types_select = [
  { label: 'Limpiador', value: Constants.LEVELS.CLEANER },
  { label: 'Supervisor', value: Constants.LEVELS.SUPERVISOR }
];

const getStatusColor = (status) => {
  switch (status) {
    case Constants.USER.STATUS.PENDING:
      return 'success';

    case Constants.USER.STATUS.ACTIVE:
      return 'secondary';

    case Constants.USER.STATUS.INACTIVE:
      return '[#e11d48]';

    default:
      return 'warning';
  }
}

const MODAL_ACTION = {
  NONE: 0,
  VIEW: 1,
  CREATE: 2,
  EDIT: 3,
  LAYOFF: 4,
  DELETE: 5,
}

const Cleaners = () => {

  const [selectedCleaner, setSelectedCleaner] = useState();
  const [selectedImage, setSelectedImage] = useState();
  const [modalAction, setModalAction] = useState(MODAL_ACTION.NONE);
  const { canResetFilter, cleaners, deleteCleaner, restoreDeleteIosAndroidCleaner, filterBy, goToPage, isLoading, pagination, reload, updateCleaner } = useFetchTable();

  const accept = (cleaner) => {
    updateCleaner({
      id: cleaner.id,
      status: Constants.USER.STATUS.ACTIVE,
      verified: Constants.USER.VERIFIED.APPROVED,
    });
  }

  const reject = (cleaner) => {
    updateCleaner({
      ...cleaner,
      status: Constants.USER.STATUS.INACTIVE,
      verified: Constants.USER.VERIFIED.REJECTED,
    });
    closeModal();
  }

  const layOff = () => {
    updateCleaner({
      id: selectedCleaner.id,
      status: Constants.USER.STATUS.INACTIVE,
      verified: Constants.USER.VERIFIED.APPROVED,
    });
    closeModal();
  }

  const onSelectItem = (cleaner, action) => {
    setSelectedCleaner(cleaner);
    setModalAction(action);
  }

  const onOpenImage = (image) => {
    if (!image) return toast.error('Imagen no disponible');
    setSelectedImage(fromPhotos(image));
  }

  const closeModal = (reloading = false) => {
    setSelectedCleaner(null);
    setModalAction(MODAL_ACTION.NONE);
    if (reloading) reload();
  }

  return (
    <>
      {isLoading && (
        <div className="w-screen h-screen fixed inset-0 z-[70] flex justify-center items-center bg-white/30">
          <Spinner />
        </div>
      )}

      <Modal
        isOpen={!!selectedImage}
        onClose={() => setSelectedImage(null)}
        backdrop="blur"
      >
        {selectedImage && (
          <div className="w-screen h-screen fixed inset-0 z-[100] flex justify-center items-center bg-white/30" onClick={() => setSelectedImage(null)}>
            <IconX size={28} className="absolute top-4 right-8" onClick={() => setSelectedImage(null)} />
            <Image classNames={{ img: 'object-contain max-w-3xl max-h-[80vh] ' }} alt="" src={selectedImage} />
          </div>
        )}
      </Modal>

      <Modal
        size="sm"
        isOpen={!!selectedCleaner || modalAction === MODAL_ACTION.CREATE}
        onClose={() => closeModal()}
        backdrop="blur"
        scrollBehavior="outside"
      >
        <ModalContent>
          {(onClose) => {
            if (modalAction === MODAL_ACTION.VIEW) return (
              <ModalView
                cleaner={selectedCleaner}
                onClose={onClose}
                onOpenImage={onOpenImage}
                onEdit={() => setModalAction(MODAL_ACTION.EDIT)}
              />
            );
            if (modalAction === MODAL_ACTION.EDIT) return (
              <ModalEdit
                cleaner={selectedCleaner}
                onClose={onClose}
                onSuccess={() => closeModal(true)}
              />
            );
            if (modalAction === MODAL_ACTION.CREATE) return (
              <ModalCreate
                onClose={onClose}
                onSuccess={() => closeModal(true)}
              />
            );
            if (modalAction === MODAL_ACTION.LAYOFF) return (
              <ModalLayoff
                cleaner={selectedCleaner}
                onClose={onClose}
                onLayOff={layOff}
              />
            );
            if (modalAction === MODAL_ACTION.DELETE) return (
              <ModalDelete
                onClose={onClose}
                onDelete={()=> {
                  deleteCleaner(selectedCleaner?.id);
                  closeModal(true);
                }}
              />
            );
            if (modalAction === MODAL_ACTION.RESTORE_DELETE_IOS_ANDROID) return (
              <ModalRestoreDeleteIosAndroid
                onClose={onClose}
                onDelete={()=> {
                  restoreDeleteIosAndroidCleaner(selectedCleaner?.id);
                  closeModal(true);
                }}
              />
            );
          }}
        </ModalContent>
      </Modal>

      <Filters
        canResetFilter={canResetFilter}
        filterBy={filterBy}
        resetFilter={() => reload()}
      />

      <Table aria-label="Usuarios registrados"
        topContent={
          <div className="flex flex-row justify-between items-center gap-4">
            <h3 className="text-xl font-medium text-primaryDark">Usuarios registrados</h3>
            <Button
              color="primary"
              className="pl-2"
              startContent={<IconPlus color="white" />}
              onClick={() => onSelectItem(null, MODAL_ACTION.CREATE)}
            >
              Nuevo
            </Button>
          </div>
        }
        topContentPlacement="inside"
      >
        <TableHeader>
          <TableColumn>Nombre</TableColumn>
          <TableColumn>Teléfono</TableColumn>
          <TableColumn>Tipo</TableColumn>
          <TableColumn>Fecha inicio actividades</TableColumn>
          <TableColumn>Estatus</TableColumn>
          <TableColumn>Solicitud</TableColumn>
          <TableColumn align="end" />
        </TableHeader>
        <TableBody items={cleaners}>
          {(cleaner) => {
            const { STATUS, VERIFIED } = Constants.USER;
            const isPending = cleaner.status === STATUS.PENDING && cleaner.verified === VERIFIED.PENDING;
            const isActive = cleaner.status === STATUS.ACTIVE && cleaner.verified === VERIFIED.APPROVED;
            const isSuspended = cleaner.status === STATUS.INACTIVE && cleaner.verified === VERIFIED.APPROVED;

            return (
              <TableRow key={cleaner.id}>
                <TableCell>
                  <User
                    avatarProps={{ showFallback: true, radius: 'full', src: cleaner?.person?.photo ? fromPhotos(cleaner.person.photo) : null }}
                    description={cleaner.email}
                    name={cleaner?.person?.fullName}
                  />
                </TableCell>
                <TableCell>{ cleaner?.person?.phone }</TableCell>
                <TableCell>{ cleaner?.level?.name }</TableCell>
                <TableCell>
                  {
                    cleaner.start_date 
                      ? moment(cleaner.start_date).format('DD MMMM YYYY') 
                      : (cleaner.first_execution_ended_at 
                          ? moment(cleaner.first_execution_ended_at).format('DD MMMM YYYY') 
                          : ''
                        )
                  }
                </TableCell>
                <TableCell>
                  <span className={`text-${getStatusColor(cleaner.status)}`}>{ cleaner.statusText }</span>
                </TableCell>
                <TableCell className="flex gap-2 pl-0 pr-0">
                  {isPending && (
                    <>
                      <Chip variant="flat" color="primary" className="cursor-pointer" onClick={() => accept(cleaner)}>
                        Aceptar
                      </Chip>
                      <Chip variant="flat" color="danger" className="cursor-pointer" onClick={() => reject(cleaner)}>
                        Rechazar
                      </Chip>
                    </>
                  )}
                </TableCell>
                <TableCell align="right">
                  <div className="relative flex justify-end items-center gap-2">
                    {isSuspended && (
                      <Tooltip content="Activar">
                        <IconUserCheck onClick={() => accept(cleaner)} />
                      </Tooltip>
                    )}
                    {isActive && (
                      <Tooltip content="Suspender">
                        <IconUserPause onClick={() => onSelectItem(cleaner, MODAL_ACTION.LAYOFF)} />
                      </Tooltip>
                    )}
                    <Tooltip content="Ver detalles">
                      <IconEye onClick={() => onSelectItem(cleaner, MODAL_ACTION.VIEW)} />
                    </Tooltip>
                    <Tooltip color="danger" content="Eliminar">
                      <IconTrash onClick={() => onSelectItem(cleaner, MODAL_ACTION.DELETE)} />
                    </Tooltip>
                    {cleaner.delete_ios != null && (
                      <Tooltip color="danger" content="Restaurar eliminado desde ios/android">
                        <IconHistory onClick={() => onSelectItem(cleaner, MODAL_ACTION.RESTORE_DELETE_IOS_ANDROID)} />
                      </Tooltip>
                    )}
                  </div>
                </TableCell>
              </TableRow>
            )
          }}
        </TableBody>
      </Table>

      <div className="flex w-full justify-center mt-4">
        <Pagination
          showControls
          variant="bordered"
          page={pagination.page}
          total={pagination.pages}
          onChange={goToPage}
        />
      </div>
    </>
  );
}

const Filters = ({ canResetFilter, filterBy, resetFilter }) => {

  const initialFilter = {
    search: '',
    since: '',
    until: '',
    type: ''
  };
  const [form, setForm] = useState(initialFilter);

  const onChange = (value, target) => {
    setForm(s => ({ ...s, [target]: value }));
    filterBy(value, target);
  }

  return (
    <section className="mb-4 flex flex-col lg:flex-row items-end gap-4">
      <Input
        classNames={{
          base: 'w-full sm:max-w-[14rem]',
          inputWrapper: 'border-1 h-10 bg-white',
        }}
        label="Buscar"
        labelPlacement="outside"
        placeholder="Nombre del usuario"
        startContent={<IconSearch />}
        variant="bordered"
        value={form.search}
        onValueChange={v => onChange(v, 'search')}
      />
      <Select
        label="Tipo de Usuario"
        labelPlacement="outside"
        placeholder="Seleccionar"
        variant="bordered"
        className="max-w-xs"
        classNames={{ base: 'w-full sm:max-w-[11rem]', trigger: 'border-1 bg-white' }}
        disallowEmptySelection
        selectedKeys={form.type ? [form.type]:[]}
        onSelectionChange={v => onChange(v.currentKey,'type')}
      >
        {types_select.map((status) => (
          <SelectItem key={status.value} value={status.value}>
            { status.label }
          </SelectItem>
        ))}
      </Select>
      <Input
        type="date"
        classNames={{
          base: 'w-full sm:max-w-[9rem]',
          inputWrapper: 'border-1 h-10 bg-white',
          input: `pr-0 text-${!!form.since ? '[]':'foreground-400'}`,
        }}
        label="Desde"
        labelPlacement="outside"
        placeholder=" "
        variant="bordered"
        value={form.since}
        onValueChange={v => onChange(v, 'since')}
      />
      <Input
        type="date"
        classNames={{
          base: 'w-full sm:max-w-[9rem]',
          inputWrapper: 'border-1 h-10 bg-white',
          input: `pr-0 text-${!!form.until ? '[]':'foreground-400'}`,
        }}
        label="Hasta"
        labelPlacement="outside"
        placeholder=" "
        variant="bordered"
        value={form.until}
        onValueChange={v => onChange(v, 'until')}
      />
      {canResetFilter && (
        <Button
          variant="light"
          className="text-primaryDark"
          onClick={() => {
            setForm(initialFilter);
            resetFilter();
          }}
        >
          Limpiar filtros
        </Button>
      )}
    </section>
  )
}

const ModalView = ({ cleaner, onOpenImage, onEdit, onClose }) => {
  const startDate = cleaner.start_date || cleaner.first_execution_ended_at || '';

  const onDownload = async (url) => {
    if (!url) return;
    const res = await fetch(fromPhotos(url));
    const blob = await res.blob();
    const extension = String(url).split('.').pop();
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${cleaner?.person?.fullName}_${Date.now()}.${extension}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <div className="items-start pt-4">
      <ModalBody className="items-start">
        <User
          avatarProps={{ radius: 'full', src: cleaner?.person?.photo ? fromPhotos(cleaner.person.photo) : null }}
          classNames={{ name: 'text-md font-medium' }}
          description={(
            <>
              <p>Fecha de registro: { moment(cleaner?.created_at).format('DD MMMM YYYY') }</p>
              <p>Fecha inicio actividades: { startDate ? moment(startDate).format('DD MMMM YYYY') : 'No posee' }</p>
            </>
          )}
          name={cleaner?.person?.fullName}
        />

        <Divider />

        <div className="flex w-full justify-between items-center">
          <div className="inline-flex items-center gap-4 cursor-pointer" onClick={() => onOpenImage(cleaner?.person?.documentPhoto)}>
            <Avatar showFallback src={cleaner?.person?.documentPhoto ? fromPhotos(cleaner?.person?.documentPhoto) : ''} />
            <span className="text-sm">Ver identificación</span>
          </div>
          <IconDownload onClick={() => onDownload(cleaner?.person?.documentPhoto)} />
        </div>

        <div className="flex w-full justify-between items-center">
          <div className="inline-flex items-center gap-4 cursor-pointer" onClick={() => onOpenImage(cleaner?.person?.photo)}>
            <Avatar showFallback src={cleaner?.person?.photo ? fromPhotos(cleaner?.person?.photo) : ''} />
            <span className="text-sm">Ver foto de usuario</span>
          </div>
          <IconDownload onClick={() => onDownload(cleaner?.person?.photo)} />
        </div>

        <Divider className="my-2" />

        <div className="w-full">
          <div className="flex text-sm items-center gap-2 text-primary">
            <IconPhone size={20} />
            <p>Teléfono</p>
          </div>
          <p className="text-sm">{ cleaner?.person?.phone }</p>

          <div className="flex text-sm items-center gap-2 text-primary mt-2">
            <IconMail size={20} />
            <p>Correo</p>
          </div>
          <p className="text-sm">{ cleaner?.email }</p>

          <div className="flex text-sm items-center gap-2 text-primary mt-2">
            <IconMapPin size={20} />
            <p>Dirección</p>
          </div>
          <p className="text-sm">{ cleaner?.person?.address }</p>

          <div className="flex text-sm items-center gap-2 text-primary mt-2">
            <IconUser size={20} />
            <p>Tipo de Usuario</p>
          </div>
          <p className="text-sm">{ cleaner?.level?.name }</p>

          <Divider className="my-4" />

          <p className="text-md font-medium text-blue-800 mt-2">Datos Bancarios</p>
          <p className="text-sm font-medium text-blue-800 mt-2">Cuenta Bancaria</p>
          <p>{ cleaner?.bank?.account_number }</p>
          <p className="text-sm font-medium text-blue-800 mt-2">Código SWIFT / BIC</p>
          <p>{ cleaner?.bank?.bank_name }</p>
          <p className="text-sm font-medium text-blue-800 mt-2">ACH routing number(ABA)</p>
          <p>{ cleaner?.bank?.route_number }</p>
        </div>
      </ModalBody>
      <ModalFooter className="justify-evenly">
        <Button variant="light" color="danger" onPress={onEdit}>Editar</Button>
        <Button variant="light"  onPress={onClose}>Cerrar</Button>
      </ModalFooter>
    </div>
  )
}

const ModalLayoff = ({ onClose, onLayOff }) => {
  return (
    <>
      <ModalHeader className="flex flex-col gap-1">Confirmación</ModalHeader>
      <ModalBody>
        <p>¿Estás seguro de suspender este usuario?</p>
      </ModalBody>
      <ModalFooter className="justify-evenly">
        <Button variant="light" onPress={onClose}>Cerrar</Button>
        <Button color="primary" onPress={onLayOff}>Aceptar</Button>
      </ModalFooter>
    </>
  )
}

const ModalDelete = ({ onClose, onDelete }) => {
  return (
    <>
      <ModalHeader className="flex flex-col gap-1">Confirmación</ModalHeader>
      <ModalBody>
        <p>¿Estás seguro de eliminar este usuario?</p>
      </ModalBody>
      <ModalFooter className="justify-evenly">
        <Button variant="light" onPress={onClose}>Cancelar</Button>
        <Button color="primary" onPress={onDelete}>Aceptar</Button>
      </ModalFooter>
    </>
  )
}

const ModalRestoreDeleteIosAndroid = ({ onClose, onDelete }) => {
  return (
    <>
      <ModalHeader className="flex flex-col gap-1">Confirmación</ModalHeader>
      <ModalBody>
        <p>¿Estás seguro de restaurar este usuario?</p>
      </ModalBody>
      <ModalFooter className="justify-evenly">
        <Button variant="light" onPress={onClose}>Cancelar</Button>
        <Button color="primary" onPress={onDelete}>Aceptar</Button>
      </ModalFooter>
    </>
  )
}

const useFetchTable = () => {
  const initialFilters = {
    page: 1,
    perPage: Constants.PER_PAGE,
    search: '',
    since: null,
    until: null,
    type: ''
  };

  const initialPagination = {
    page: 1,
    pages: 1,
    total: 0,
    perPage: Constants.PER_PAGE,
  };

  const [data, setData] = useState([]);
  const [canFetch, setCanFetch] = useState(true);
  const [filters, setFilters] = useState(initialFilters);
  const [pagination, setPagination] = useState(initialPagination);

  const debounceTime = 500;
  const debounce = useRef();

  const fetchData = async () => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      const response = await CleanersService.findAll(filters);
      const { data, ...rest } = response;

      setData(data);
      setPagination(rest);
      setCanFetch(true);

    } catch (error) {
      setData([]);
      onError(String(error));
    }
  }

  const deleteItem = async (id) => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      await CleanersService.destroy(id);
      toast.success('Usuario eliminado con éxito');
      fetchData();

    } catch (error) {
      onError(String(error));
    }
    setCanFetch(true);
  }

  const restoreDeleteIosAndroidItem = async (id) => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      await CleanersService.restoreDeleteIosAndroid(id);
      toast.success('Usuario restaurado con éxito');
      fetchData();

    } catch (error) {
      onError(String(error));
    }
    setCanFetch(true);
  }

  const updateItem = async (cleaner) => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      await CleanersService.update(cleaner);
      toast.success('Usuario actualizado con éxito');
      fetchData();

    } catch (error) {
      onError(String(error));
    }
    setCanFetch(true);
  }

  const getCurrentPagination = () => {
    // Truco para obtener el estado actualizado (pagination es mantenida con el estado actual por el componente Pagination)
    let pag;
    setPagination(s => {
      pag = s;
      return s;
    });
    return pag;
  }

  const canResetFilter = () => {
    const { page, perPage, ...initial } = initialFilters;
    const { page: _, perPage: __, ...current } = filters;
    const initFilter = JSON.stringify(initial);
    const currFilter = JSON.stringify(current);
    return initFilter !== currFilter;
  }

  const onError = (msg) => toast.error(msg);

  const reload = (inSamePage = false) => {
    setCanFetch(true);
    if (!inSamePage) setFilters(initialFilters);
    else fetchData();
  }

  const goToPage = (page) => {
    const pagination = getCurrentPagination();
    if (page >= 1 && page <= pagination.pages && page !== pagination.page) {
      setCanFetch(true);
      setFilters({ ...filters, page });
    }
  }

  const changePerPage = (perPage) => {
    setCanFetch(true);
    setFilters({ ...filters, perPage });
  }

  const filterBy = (value, target) => {
    if (debounce.current) clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      setCanFetch(true);
      setFilters({ ...filters, page: 1, [target]: value });
    }, debounceTime);
  }

  useEffect(() => {
    fetchData();
  }, [filters]);

  return {
    canResetFilter: canResetFilter(),
    changePerPage,
    cleaners: data,
    deleteCleaner: deleteItem,
    restoreDeleteIosAndroidCleaner: restoreDeleteIosAndroidItem,
    filterBy,
    goToPage,
    isLoading: !canFetch,
    pagination,
    reload,
    updateCleaner: updateItem,
  }
}

export default Cleaners;
