import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Reports = () => {
   const navigate = useNavigate();
   
   useEffect(() => {
      navigate('/reports/work');
   },[]);
}

export default Reports;