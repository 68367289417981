import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Button, Input } from '@nextui-org/react';
import { AuthService } from '../../services';
import { Logo } from '../../assets/images';

const RecoverPassword = () => {

  const navigate = useNavigate();
  const [formData, setFormData] = useState({ email: '' });
  const [isLoading, setIsLoading] = useState(false);

  const onChange = (target, value) => {
    setFormData(s => ({ ...s, [target]: value }));
  }

  const isValidForm = () => {
    const onError = (msg) => {
      toast.error(msg);
      return false;
    }

    if (!formData.email)
      return onError('El correo es obligatorio');

		if (!String(formData.email).match(/^[a-z0-9._-]+@[a-z0-9._-]+\.[a-z]{2,3}$/ig))
			return onError('El correo no es válido');

    return true;
  }

  const submit = async (event) => {
    event.preventDefault();
    if (!isValidForm()) return;
    setIsLoading(true);

    try {
      await AuthService.recoverPassword(formData);
      toast.success('Se ha enviado a su correo el enlace de recuperación');
      navigate('/');

    } catch (error) {
      console.log(error);
      toast.error(error);
    }
    setIsLoading(false);
  }

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-32 w-auto"
          src={Logo}
          alt={process.env.REACT_APP_NAME}
        />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Recuperar contraseña
        </h2>
      </div>

      <p className="text-center my-5 text-sm">
        Ingrese su correo electrónico y recibirá un enlace de acceso
      </p>

      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6">
          <div className="text-sm font-medium leading-6 flex flex-col gap-4">
            <Input
              label="Correo"
              placeholder="Correo electrónico"
              labelPlacement="outside"
              type="email"
              variant="bordered"
              radius="sm"
              value={formData.email}
              onValueChange={v => onChange('email', v)}
            />
          </div>

          <Button color="primary" radius="sm" fullWidth isLoading={isLoading} onClick={submit}>
            Enviar
          </Button>
        </form>
      </div>
    </div>
  )
}

export default RecoverPassword;