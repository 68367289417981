export const Constants = {
	PER_PAGE: 30,
	LEVELS: {
		ADMIN: 1,
		CLEANER: 2,
		SUPERVISOR: 3
	},
	USER: {
		STATUS: {
			PENDING: 0,
			ACTIVE: 1,
			INACTIVE: 2,
		},
		VERIFIED: {
			PENDING: 0,
			APPROVED: 1,
			REJECTED: 2,
		}
	},
	ORDER: {
		STATUS: {
			PENDING: 0,
			INITIATED: 1,
			FINISHED: 2,
			CANCELLED: 3,
		},
	},
}