import { fetchWrapper } from '../utils';

const baseUrl = process.env.REACT_APP_BASE_API + 'admin/reports';

export async function getWorkReport(params) {
  const url = `${baseUrl}/work-report`;
  return await fetchWrapper.post(url, params);
}

export async function exportWorkReport(params) {
  const url = `${baseUrl}/work-report/export`;
  return await fetchWrapper.post(url, params);
}