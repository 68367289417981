import { fetchWrapper } from '../utils';

const baseUrl = process.env.REACT_APP_BASE_API + 'google';

export async function autocomplete(filters) {
  const url = `${baseUrl}/autocomplete`;
  return await fetchWrapper.post(url, filters);
}

export async function geoCode(filters) {
  const url = `${baseUrl}/geoCode`;
  return await fetchWrapper.post(url, filters);
}

export async function getAddresByLatlng(filters) {
  const url = `${baseUrl}/getAddresByLatlng`;
  return await fetchWrapper.post(url, filters);
}