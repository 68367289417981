/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';

const Payments = () => {
  return (
    <>
      
    </>
  );
}

export default Payments;