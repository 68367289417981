/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { Select, Input, Button, SelectItem, Pagination, Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, User, Popover, PopoverTrigger, PopoverContent, Spinner } from '@nextui-org/react';
import toast from 'react-hot-toast';
import { IconSearch } from '@tabler/icons-react';
import moment from 'moment';
import { ReportService } from '../../services';
import { clone, fromPhotos, fromStorage, getClockTotalTime } from '../../utils';
// Establece el Lunes como primer día de la semana
moment.locale('es');

const TYPES = {
  DAY: '1',
  WEEK: '2',
  MONTH: '3'
}

const types_select = [
  { label: 'Diario', value: TYPES.DAY },
  { label: 'Semanal', value: TYPES.WEEK },
  { label: 'Mensual', value: TYPES.MONTH }
];

const rowColor = [
  {
    bg: '#B1FCDE',
    border: '#a8f0d3',
  },
  {
    bg: '#B2E5FA',
    border: '#9ddcf7',
  },
  {
    bg: '#EBB2F7',
    border: '#d69be2',
  },
];

const WorkReport = () => {
  const { canResetFilter, data, filters, filterBy, goToPage, isLoading, hoursRange, pagination, print, reload } = useFetchTable();
  const dayGridLength = hoursRange.length;
  const dayGridTemplateColumns = `repeat(${dayGridLength},minmax(4rem,1fr))`;
  let rowIdx = 0;

  return (
    <>
      {isLoading && (
        <div className="w-screen h-screen fixed inset-0 z-[70] flex justify-center items-center bg-white/30">
          <Spinner />
        </div>
      )}

      <Filters
        canResetFilter={canResetFilter}
        filterBy={filterBy}
        print={print}
        resetFilter={() => reload()}
      />

      {filters.type === TYPES.DAY && (
        <Table aria-label="Desempeño Diario" classNames={{ td: 'border-t-1 first:border-r-1 border-collapse' }}>
          <TableHeader>
            <TableColumn>Trabajador</TableColumn>
            <TableColumn>
              <div className="grid gap-1 items-center" style={{ gridTemplateColumns: dayGridTemplateColumns }}>
                {hoursRange.map((x, index) => (
                  <div key={index}>{ x.format('ha') }</div>
                ))}
              </div>
            </TableColumn>
          </TableHeader>
          <TableBody items={data}>
            {(item) => {
              rowIdx++;
              const colorIdx = rowIdx % rowColor.length;
              const bgColor = rowColor[colorIdx].bg;
              const borderColor = rowColor[colorIdx].border;

              return (
                <TableRow key={item.id}>
                  <TableCell className="pl-0">
                    <div className="w-48 overflow-hidden">
                      <User
                        classNames={{ name: 'truncate', description: 'truncate' }}
                        avatarProps={{ showFallback: true, radius: 'full', src: item?.person?.photo ? fromPhotos(item.person.photo) : null }}
                        description={item.email}
                        name={item?.person?.fullName}
                        title={item?.person?.fullName}
                      />
                    </div>
                  </TableCell>
                  <TableCell className="p-1">
                    <div
                      className="grid gap-1 items-center"
                      style={{ gridTemplateColumns: `repeat(${dayGridLength},minmax(4rem,1fr))` }}
                    >
                      {item?.orders_cleaner?.map(oc => oc?.executions?.map((exec, index) => {
                        // Obtiene la duración del item para establecer el largo en la tabla
                        const timeStartsAt = moment(exec.date + ' ' + oc.order.time_starts_at);
                        const currentDate = moment(moment().format('YYYY-MM-DD'));
                        const endMinutes = moment(oc.order.time_ends_at, 'HH:mm:ss').get('minutes');
                        const startHour = moment(oc.order.time_starts_at, 'HH:mm:ss').get('hour');
                        const endHour = moment(oc.order.time_ends_at, 'HH:mm:ss').get('hour') + (endMinutes > 0 ? 1 : 0);
                        const itemLength = endHour - startHour;

                        // Busca la ubicación en la celda en base a la hora y duración del trabajo
                        const gridCellIndex = hoursRange.findIndex(cell => cell.get('hour') === startHour);
                        const gridColumnStart = gridCellIndex + 1;
                        const gridColumnEnd = gridColumnStart + itemLength;
                        let delay = 0;

                        // Calcula el tiempo de retraso
                        if (exec.started_at) {
                          // Calcula el tiempo de retraso verificando los minutos de diferencia al horario establecido
                          const startTime = moment(exec.started_at);
                          const startMinutesDiff = timeStartsAt.diff(startTime, 'minutes');

                          // Comenzó después, tiempo de retraso
                          if (startMinutesDiff < 0) delay += Math.abs(startMinutesDiff);

                        } else if (moment(exec.date).isSameOrBefore(currentDate)) {
                          // Establece el momento actual o final del día como límite de hora de retraso
                          let maxDelayTime = moment(exec.date).add(1,'day');
                          const now = moment();
                          if (now.isBefore(maxDelayTime)) maxDelayTime = now;

                          // Calcula el tiempo de retraso verificando los minutos de diferencia al horario establecido
                          const startMinutesDiff = timeStartsAt.diff(maxDelayTime, 'minutes');

                          // Comenzó después, tiempo de retraso
                          if (startMinutesDiff < 0) delay += Math.abs(startMinutesDiff);
                        }

                        const content = (
                          <>
                            <div className="truncate">{ oc.order.store.name }</div>
                            <div className="truncate">{ moment(oc.order.time_starts_at, 'HH:mm:ss').format('h:mma') }</div>
                            {delay > 0 && (
                              <div className="truncate">Tiempo de retraso (hrs/min): { getClockTotalTime(delay) }</div>
                            )}
                          </>
                        );

                        return (
                          <Popover
                            key={index}
                            placement="top"
                            classNames={{
                              content: 'rounded-md border-1 p-2 leading-4 flex flex-1 flex-col justify-end',
                            }}
                          >
                            <PopoverTrigger>
                              <div
                                className="h-16 rounded-md border-1 p-2 leading-4 flex flex-1 flex-col justify-end"
                                style={{ backgroundColor: bgColor, borderColor: borderColor, gridColumnStart, gridColumnEnd, zIndex: gridColumnStart }}
                              >
                                { content }
                              </div>
                            </PopoverTrigger>
                            <PopoverContent style={{ backgroundColor: bgColor, borderColor: borderColor }}>
                              { content }
                            </PopoverContent>
                          </Popover>
                        )
                      }))}
                    </div>
                  </TableCell>
                </TableRow>
              )
            }}
          </TableBody>
        </Table>
      )}

      {filters.type === TYPES.WEEK && data.map((item, index) => (
        <Table
          key={index}
          aria-label="Desempeño Semanal"
          classNames={{
            base: 'mb-4',
            thead: 'p-0',
            wrapper: 'p-0',
            td: 'border-t-1 first:border-r-1 border-collapse',
          }}
        >
          <TableHeader>
            <TableColumn className="w-48">
              <div className="w-48 overflow-hidden">
                <User
                  classNames={{ name: 'truncate', description: 'truncate' }}
                  avatarProps={{ showFallback: true, radius: 'full', src: item?.person?.photo ? fromPhotos(item.person.photo) : null }}
                  name={item?.person?.fullName}
                  title={item?.person?.fullName}
                />
              </div>
            </TableColumn>
            {item?.calendar?.map(({ label }) => (
              <TableColumn key={label}>{ label }</TableColumn>
            ))}
            <TableColumn>Totales (Horas/min)</TableColumn>
            <TableColumn>Desempeño</TableColumn>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell className="bg-[#B2E5FA] font-semibold">
                Horario programado
              </TableCell>
              {item?.calendar?.map((calItem, calIdx) => (
                <TableCell key={calIdx}>
                  <div className="grid" style={{ gridTemplateColumns: `repeat(${calItem?.executions?.length},minmax(4rem,1fr))` }}>
                    {calItem?.executions?.map((exec, execIdx) => (
                      <div key={execIdx}>
                        <div>{ exec.scheduledStart }</div>
                        <div>{ exec.scheduledEnd }</div>
                      </div>
                    ))}

                    {!calItem?.executions?.length && '--'}
                  </div>
                </TableCell>
              ))}
              <TableCell>{ getClockTotalTime(item?.scheduled) }</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="bg-[#B2E5FA] border-t-gray-400 font-semibold">
                Horario trabajado
              </TableCell>
              {item?.calendar?.map((calItem, calIdx) => (
                <TableCell key={calIdx}>
                  <div className="grid" style={{ gridTemplateColumns: `repeat(${calItem?.executions?.length},minmax(4rem,1fr))` }}>
                    {calItem?.executions?.map((exec, execIdx) => (
                      <div key={execIdx}>
                        <div>{ exec.workedStart }</div>
                        <div>{ exec.workedEnd }</div>
                      </div>
                    ))}

                    {!calItem?.executions?.length && '--'}
                  </div>
                </TableCell>
              ))}
              <TableCell>{ getClockTotalTime(item?.worked) }</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="bg-[#F9B1B1] border-t-gray-400 font-semibold">
                Tiempo de retraso (Hrs/min)
              </TableCell>
              {item?.calendar?.map((calItem, calIdx) => (
                <TableCell key={calIdx}>
                  <div className="grid" style={{ gridTemplateColumns: `repeat(${calItem?.executions?.length},minmax(3rem,1fr))` }}>
                    {calItem?.executions?.map((exec, execIdx) => (
                      <div key={execIdx}>
                        { isNaN(exec?.delay) ? exec?.delay : (getClockTotalTime(exec?.delay) || '--') }
                      </div>
                    ))}

                    {!calItem?.executions?.length && '--'}
                  </div>
                </TableCell>
              ))}
              <TableCell>{ getClockTotalTime(item?.delay) }</TableCell>
              <TableCell>
                {item?.delay > 0 && (
                  <span className="text-red">Incumplió</span>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      ))}

      {filters.type === TYPES.MONTH && (
        <Table aria-label="Desempeño Mensual" classNames={{ td: 'border-t-1 first:border-r-1 border-collapse' }}>
          <TableHeader>
            <TableColumn>Trabajador</TableColumn>
            <TableColumn>Horas programadas</TableColumn>
            <TableColumn>Horas trabajadas</TableColumn>
            <TableColumn>Tiempo retraso (hrs/min)</TableColumn>
            <TableColumn>Tiempo Extra (hrs/min)</TableColumn>
          </TableHeader>
          <TableBody items={data}>
            {(item) => (
              <TableRow key={item.id}>
                <TableCell className="pl-0">
                  <User
                    classNames={{ name: 'truncate', description: 'truncate' }}
                    avatarProps={{ showFallback: true, radius: 'full', src: item?.person?.photo ? fromPhotos(item.person.photo) : null }}
                    description={item?.email}
                    name={item?.person?.fullName}
                    title={item?.person?.fullName}
                  />
                </TableCell>
                <TableCell>{ getClockTotalTime(item?.scheduled) }</TableCell>
                <TableCell>{ getClockTotalTime(item?.worked) }</TableCell>
                <TableCell>{ getClockTotalTime(item?.delay) }</TableCell>
                <TableCell>{ getClockTotalTime(item?.extra) }</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}

      {!isLoading && (
        <div className="flex w-full justify-center mt-4">
          <Pagination
            showControls
            variant="bordered"
            page={pagination.page}
            total={pagination.pages}
            onChange={goToPage}
          />
        </div>
      )}
    </>
  );
}

const Filters = ({ canResetFilter, filterBy, print, resetFilter }) => {
  const initialFilter = {
    type: TYPES.DAY,
    search: '',
    date: moment().format('YYYY-MM-DD'),
    week: moment().format('YYYY-MM-DD'),
    month: moment().format('YYYY-MM-DD'),
  };
  const [form, setForm] = useState(initialFilter);

  const onChange = (value, target) => {
    setForm(s => ({ ...s, [target]: value }));
    filterBy(value, target);
  }

  return (
    <section className="mb-4 flex flex-col lg:flex-row flex-wrap items-end gap-4">
      <Select
        label="Desempeño"
        labelPlacement="outside"
        placeholder="Seleccionar"
        variant="bordered"
        className="max-w-xs"
        classNames={{ base: 'w-full sm:max-w-[11rem]', trigger: 'border-1 bg-white' }}
        disallowEmptySelection
        selectedKeys={form.type ? [form.type]:[]}
        onSelectionChange={v => onChange(v.currentKey, 'type')}
      >
        {types_select.map((status) => (
          <SelectItem key={status.value} value={status.value}>
            { status.label }
          </SelectItem>
        ))}
      </Select>
      <Input
        classNames={{
          base: 'w-full sm:max-w-[20rem]',
          inputWrapper: 'border-1 h-10 bg-white',
        }}
        label="Buscar"
        labelPlacement="outside"
        startContent={<IconSearch />}
        isClearable
        placeholder="Nombre"
        onClear={() => onChange('', 'search')}
        variant="bordered"
        value={form.search}
        onValueChange={v => onChange(v, 'search')}
      />
      {form.type === TYPES.DAY && (
        <Input
          type="date"
          classNames={{
            base: 'w-full sm:max-w-[9rem]',
            inputWrapper: 'border-1 h-10 bg-white',
            input: `pr-0 text-${!!form.date ? '[]':'foreground-400'}`,
          }}
          label="Fecha"
          labelPlacement="outside"
          placeholder=" "
          variant="bordered"
          value={form.date}
          onValueChange={v => onChange(v, 'date')}
        />
      )}
      {form.type === TYPES.WEEK && (
        <Input
          type="date"
          classNames={{
            base: 'w-full sm:max-w-[11rem]',
            inputWrapper: 'border-1 h-10 bg-white',
            input: `pr-0 text-${!!form.week ? '[]':'foreground-400'}`,
          }}
          label="Semana"
          labelPlacement="outside"
          placeholder=" "
          variant="bordered"
          value={form.week}
          onValueChange={v => onChange(v, 'week')}
        />
      )}
      {form.type === TYPES.MONTH && (
        <Input
          type="date"
          classNames={{
            base: 'w-full sm:max-w-[11rem]',
            inputWrapper: 'border-1 h-10 bg-white',
            input: `pr-0 text-${!!form.month ? '[]':'foreground-400'}`,
          }}
          label="Mes"
          labelPlacement="outside"
          placeholder=" "
          variant="bordered"
          value={form.month}
          pickerMonthList={true}
          onValueChange={v => onChange(v, 'month')}
        />
      )}
      {canResetFilter && (
        <Button
          variant="light"
          className="text-primaryDark"
          onClick={() => {
            setForm(initialFilter);
            resetFilter();
          }}
        >
          Limpiar filtros
        </Button>
      )}
      {[TYPES.MONTH, TYPES.WEEK].includes(form.type) && (
        <Button variant="bordered" onClick={() => print()}>
          Exportar
        </Button>
      )}
    </section>
  )
}

const useFetchTable = () => {
  const initialFilters = {
    page: 1,
    perPage: 20,
    type: TYPES.DAY,
    search: '',
    date: moment().format('YYYY-MM-DD'),
    week: moment().format('YYYY-MM-DD'),
    month: moment().format('YYYY-MM-DD'),
  };

  const initialPagination = {
    page: 1,
    pages: 1,
    total: 0,
    perPage: 20,
  };

  const [data, setData] = useState([]);
  const [hoursRange, setHoursRange] = useState([]);
  const [canFetch, setCanFetch] = useState(true);
  const [filters, setFilters] = useState(initialFilters);
  const [pagination, setPagination] = useState(initialPagination);

  const debounceTime = 500;
  const debounce = useRef();

  const fetchData = async () => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      const response = await ReportService.getWorkReport(filters);
      const { data, ...rest } = response;

      if (filters.type === TYPES.DAY) {
        const minHour = moment.min(data.map(c =>
          moment.min(c.orders_cleaner.map(oc => moment(oc.order.time_starts_at, 'HH:mm:ss')))
        ));
        const maxHour = moment.max(data.map(c =>
          moment.max(c.orders_cleaner.map(oc => moment(oc.order.time_ends_at, 'HH:mm:ss')))
        ));

        const addHour = maxHour.get('minutes') > 0;
        const lastHour = maxHour.get('hour') - (addHour ? 1 : 2);
        const hoursRange = [];

        for (let index = 0; index < lastHour; index++) {
          hoursRange.push(minHour.clone().add(index, 'hours'));
        }

        setHoursRange(hoursRange);
        setData(data);

      } else if (filters.type === TYPES.WEEK) {
        setData(parseWeekData(data));

      } else if (filters.type === TYPES.MONTH) {
        setData(parseMonthData(data));
      }

      setPagination(rest);
      setCanFetch(true);

    } catch (error) {
      setData([]);
      onError(String(error));
    }
  }

  const parseWeekData = (data) => {
    const weekDays = [
      {
        id: 1,
        label: 'Lunes',
        executions: [],
      },
      {
        id: 2,
        label: 'Martes',
        executions: [],
      },
      {
        id: 3,
        label: 'Miércoles',
        executions: [],
      },
      {
        id: 4,
        label: 'Jueves',
        executions: [],
      },
      {
        id: 5,
        label: 'Viernes',
        executions: [],
      },
      {
        id: 6,
        label: 'Sábado',
        executions: [],
      },
      {
        id: 7,
        label: 'Domingo',
        executions: [],
      },
    ];

    return data.map(item => {
      const calendar = clone(weekDays);
      let scheduledTotal = 0;
      let workedTotal = 0;
      let delayTotal = 0;
      let extraTotal = 0;

      item.orders_cleaner.forEach(oc => oc.executions.forEach(exec => {
        let scheduledStart = '--';
        let scheduledEnd = '--';
        let workedStart = '--';
        let workedEnd = '--';
        let scheduled = 0;
        let worked = 0;
        let delay = 0;
        let extra = 0;

        const timeStartsAt = moment(exec.date + ' ' + oc.order.time_starts_at);
        const timeEndsAt = moment(exec.date + ' ' + oc.order.time_ends_at);
        const currentDate = moment(moment().format('YYYY-MM-DD'));

        // Verificar el día de la semana
        const weekDay = moment(exec.date).get('isoWeekdays');

        // Establece el horario
        scheduledStart = timeStartsAt.format('h:mma');
        scheduledEnd = timeEndsAt.format('h:mma');

        // Establece la fecha de inicio y final
        if (exec.started_at) workedStart = moment(exec.started_at).format('h:mma');
        if (exec.ended_at) workedEnd = moment(exec.ended_at).format('h:mma');

        // Calcula el tiempo de retraso
        if (exec.started_at) {
          // Calcula el tiempo de retraso verificando los minutos de diferencia al horario establecido
          const startTime = moment(exec.started_at);
          const startMinutesDiff = timeStartsAt.diff(startTime, 'minutes');

          // Comenzó después, tiempo de retraso
          if (startMinutesDiff < 0) delay += Math.abs(startMinutesDiff);

        } else if (moment(exec.date).isSameOrBefore(currentDate)) {
          // Establece el momento actual o final del día como límite de hora de retraso
          let maxDelayTime = moment(exec.date).add(1,'day');
          const now = moment();
          if (now.isBefore(maxDelayTime)) maxDelayTime = now;

          // Calcula el tiempo de retraso verificando los minutos de diferencia al horario establecido
          const startMinutesDiff = timeStartsAt.diff(maxDelayTime, 'minutes');

          // Comenzó después, tiempo de retraso
          if (startMinutesDiff < 0) delay += Math.abs(startMinutesDiff);
        }

        // Calcula las horas programadas
        scheduled = timeEndsAt.diff(timeStartsAt, 'minutes');

        // Calcula el total de horas trabajadas
        worked = exec.total_time;

        // Calcula el total de horas extras
        if (worked > scheduled) extra = worked - scheduled;

        // Obtiene el día de la semana donde se guardará el dato
        const slot = calendar.findIndex(x => x.id === weekDay);
        calendar[slot].executions.push({
          delay,
          extra,
          worked,
          scheduledStart,
          scheduledEnd,
          workedStart,
          workedEnd,
        });

        // Establece los totales agrupados
        scheduledTotal += scheduled;
        workedTotal += worked;
        delayTotal += delay;
        extraTotal += extra;
      }));

      return {
        id: item.id,
        person: item.person,
        calendar,
        scheduled: scheduledTotal,
        worked: workedTotal,
        delay: delayTotal,
        extra: extraTotal,
      };
    });
  }

  const parseMonthData = (data) => {
    return data.map(item => {
      let scheduledTotal = 0;
      let workedTotal = 0;
      let delayTotal = 0;
      let extraTotal = 0;

      item.orders_cleaner.forEach(oc => oc.executions.forEach(exec => {
        let scheduled = 0;
        let worked = 0;
        let delay = 0;
        let extra = 0;

        const timeStartsAt = moment(exec.date + ' ' + oc.order.time_starts_at);
        const timeEndsAt = moment(exec.date + ' ' + oc.order.time_ends_at);
        const currentDate = moment(moment().format('YYYY-MM-DD'));

        // Calcula el tiempo de retraso
        if (exec.started_at) {
          // Calcula el tiempo de retraso verificando los minutos de diferencia al horario establecido
          const startTime = moment(exec.started_at);
          const startMinutesDiff = timeStartsAt.diff(startTime, 'minutes');

          // Comenzó después, tiempo de retraso
          if (startMinutesDiff < 0) delay += Math.abs(startMinutesDiff);

        } else if (moment(exec.date).isSameOrBefore(currentDate)) {
          // Establece el momento actual o final del día como límite de hora de retraso
          let maxDelayTime = moment(exec.date).add(1,'day');
          const now = moment();
          if (now.isBefore(maxDelayTime)) maxDelayTime = now;

          // Calcula el tiempo de retraso verificando los minutos de diferencia al horario establecido
          const startMinutesDiff = timeStartsAt.diff(maxDelayTime, 'minutes');

          // Comenzó después, tiempo de retraso
          if (startMinutesDiff < 0) delay += Math.abs(startMinutesDiff);
        }

        // Calcula las horas programadas
        scheduled = timeEndsAt.diff(timeStartsAt, 'minutes');

        // Calcula el total de horas trabajadas
        worked = exec.total_time;

        // Calcula el total de horas extras
        if (worked > scheduled) extra = worked - scheduled;

        // Establece los totales agrupados
        scheduledTotal += scheduled;
        workedTotal += worked;
        delayTotal += delay;
        extraTotal += extra;
      }));

      return {
        id: item.id,
        email: item.email,
        person: item.person,
        scheduled: scheduledTotal,
        worked: workedTotal,
        delay: delayTotal,
        extra: extraTotal,
      };
    });
  }

  const getCurrentPagination = () => {
    // Truco para obtener el estado actualizado (pagination es mantenida con el estado actual por el componente Pagination)
    let pag;
    setPagination(s => {
      pag = s;
      return s;
    });
    return pag;
  }

  const print = async () => {
    setCanFetch(false);
    try {
      const res = await ReportService.exportWorkReport(filters);
      const el = document.createElement('a');
      el.href = fromStorage(res.url);
      el.download = 'Reporte de Desempeño';
      el.target = '_blank';
      el.click();
      el.remove();

    } catch (error) {
      onError(String(error));
    }
    setCanFetch(true);
  }

  const canResetFilter = () => {
    const { page, perPage, ...initial } = initialFilters;
    const { page: _, perPage: __, ...current } = filters;
    const initFilter = JSON.stringify(initial);
    const currFilter = JSON.stringify(current);
    return initFilter !== currFilter;
  }

  const onError = (msg) => toast.error(msg);

  const reload = (inSamePage = false) => {
    setCanFetch(true);
    if (!inSamePage) setFilters(initialFilters);
    else fetchData();
  }

  const goToPage = (page) => {
    const pagination = getCurrentPagination();
    if (page >= 1 && page <= pagination.pages && page !== pagination.page) {
      setCanFetch(true);
      setFilters({ ...filters, page });
    }
  }

  const changePerPage = (perPage) => {
    setCanFetch(true);
    setFilters({ ...filters, perPage });
  }

  const filterBy = (value, target) => {
    if (debounce.current) clearTimeout(debounce.current);
    setData([]);

    debounce.current = setTimeout(() => {
      setCanFetch(true);
      setFilters({ ...filters, page: 1, [target]: value });
    }, debounceTime);
  }

  useEffect(() => {
    fetchData();
  }, [filters]);

  return {
    canResetFilter: canResetFilter(),
    changePerPage,
    data,
    filterBy,
    filters,
    goToPage,
    hoursRange,
    isLoading: !canFetch,
    pagination,
    print,
    reload,
  }
}

export default WorkReport;