import { deleteCookie, fetchWrapper, setCookie } from '../utils';

const baseUrl = process.env.REACT_APP_BASE_API + 'auth';

export async function login(form) {
  const url = `${baseUrl}/login/web`;
  const session = await fetchWrapper.post(url, form);
  setCookie('hac-session', session?.user?.token || '', 1);
  return session;
}

export async function logout() {
  removeSession();
}

export function removeSession() {
  deleteCookie('hac-session');
  window.location.replace(`${process.env.REACT_APP_BASENAME}/`);
}

export async function getProfile() {
  return await fetchWrapper.post(`${baseUrl}/get/profile`);
}

export async function recoverPassword(form) {
  return await fetchWrapper.post(`${baseUrl}/recover`, form);
}